import styles from "./DividerRule.module.css";

const DividerRule = (props) => {
  const { line_divider_text, line_size, line_color } = props.data;
  const lineText = line_divider_text;
  const textWidth = lineText.length * 1.5;
  const lineWidth = (100 - textWidth) / 2;
  return (
    <div className={styles.dividercontainer}>
      <div
        className={styles.line}
        style={{
          width: `${lineWidth}%`,
          height: `${line_size}px`,
          backgroundColor: line_color,
        }}
      ></div>
      <div className={styles.lineText} style={{ width: `${textWidth}%` }}>
        {lineText}
      </div>
      <div
        className={styles.line}
        style={{
          width: `${lineWidth}%`,
          height: `${line_size}px`,
          backgroundColor: line_color,
        }}
      ></div>
    </div>
  );
};

export default DividerRule;
