import { Pagination, Tab } from "@mgmresorts/mgm-ui";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MANAGER_DEFAULT_PAGE_SIZE } from "../../../Const";
import CustomSelectDropDown from "../../../components/common/customSelectDropDown/customSelectDropdown";
import CarouselArrows from "../../../components/dynamic/CarouselArrows";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { formatPaginationLabel } from "../../News/GivingVolunteering/VolunteerOpportunities";
import styles from "./ManagerHeader.module.css";

const ManagerHeader = (props) => {
  const { csData, data } = props;
  const { dictionaryLables } = csData;
  const [tabDetails, setTabDetails] = useState({
    isTabEnabled: false,
    tabData: [],
    selectedTab: 0,
  });
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(MANAGER_DEFAULT_PAGE_SIZE);
  const [actualEndIndex, setActualEndIndex] = useState(
    MANAGER_DEFAULT_PAGE_SIZE
  );
  const isDesktop = useMediaQuery("screen and (min-width: 768px)");
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  useEffect(() => {
    if (data && data.teams && data.teams.length > 1) {
      let tabDetailData = [];
      data.teams.forEach((team, index) => {
        return tabDetailData.push({
          name: team.name,
          isSelected: index == tabDetails.selectedTab,
          id: team.id,
          isMemberAvailable: team?.members && team?.members.length > 0,
        });
      });
      setTabDetails({
        ...tabDetails,
        tabData: tabDetailData,
        isTabEnabled: true,
      });
    }
  }, [data]);

  const getTitle = () => {
    return props.isManagerDashboard
      ? dictionaryLables.title.replace(
        '<span translate="no">{variable-total_no_of_reportees}</span>',
        data && data.totalCount ? data.totalCount : 0
      )
      : dictionaryLables.title
        .replace(
          '(<span translate="no">{variable-total_no_of_reportees}</span>)',
          data && data.totalCount ? ` (${data.totalCount})` : 0
        )
        .replace(
          '<span translate="no">{variable-reportee}</span>',
          props.state ? props.state?.managerName : ""
        );
  };

  const handleTab = (id, index) => {
    const updatedDetails = tabDetails.tabData.map((tab) => {
      if (tab.id == id) {
        return { ...tab, isSelected: true };
      }
      return { ...tab, isSelected: false };
    });
    setTabDetails({
      ...tabDetails,
      tabData: updatedDetails,
      selectedTab: index,
    });
    setStartIndex(0);
    setEndIndex(MANAGER_DEFAULT_PAGE_SIZE);
    props.setIndexValues(0, MANAGER_DEFAULT_PAGE_SIZE);
    props.selectedTab(id, index, tabDetails.tabData[index].isMemberAvailable);
    setActualEndIndex(MANAGER_DEFAULT_PAGE_SIZE);
  };

  const slidePrev = () => {
    const start = startIndex - MANAGER_DEFAULT_PAGE_SIZE;
    const end = actualEndIndex - MANAGER_DEFAULT_PAGE_SIZE;
    setStartIndex(start);
    setEndIndex(end);
    props.setIndexValues(start, end);
    setActualEndIndex(actualEndIndex - MANAGER_DEFAULT_PAGE_SIZE);
  };

  const slideNext = () => {
    const start = startIndex + MANAGER_DEFAULT_PAGE_SIZE;
    const end =
      endIndex + MANAGER_DEFAULT_PAGE_SIZE >
        data?.teams[tabDetails.selectedTab]?.members?.length
        ? data?.teams[tabDetails.selectedTab]?.members?.length
        : endIndex + MANAGER_DEFAULT_PAGE_SIZE;
    setStartIndex(start);
    setEndIndex(end);
    props.setIndexValues(start, end);
    setActualEndIndex(endIndex + MANAGER_DEFAULT_PAGE_SIZE);
  };

  const handlePagination = () => {
    const endValue =
      endIndex + MANAGER_DEFAULT_PAGE_SIZE >
        data?.teams[tabDetails.selectedTab]?.members?.length
        ? data?.teams[tabDetails.selectedTab]?.members?.length
        : endIndex + MANAGER_DEFAULT_PAGE_SIZE;
    setEndIndex(endValue);
    props.setIndexValues(0, endValue);
  };

  return (
    <Fragment>
      <div>
        <div className={styles.toptextholder}>
          <div className={styles.subTextHolder}>
            <div className={styles.managerTitle}>{getTitle()}</div>
            {isDesktop &&
              tabDetails.selectedTab.toString() &&
              data.teams[tabDetails.selectedTab]?.members &&
              data.teams[tabDetails.selectedTab]?.members.length > 0 &&
              data.teams[tabDetails.selectedTab]?.members.length >
              MANAGER_DEFAULT_PAGE_SIZE ? (

              <div className={styles.managerRightSection}>
                <span>
                  {globalProperties?.showing}{" "}
                  {/* {`${endIndex} - ${
                    data.teams[tabDetails.selectedTab].members.length
                  }`} */}
                  {`${startIndex + 1} - ${endIndex}`}
                </span>

                <CarouselArrows
                  title={{
                    prev: globalProperties?.previous,
                    next: globalProperties?.next,
                  }}
                  onClick={(e, state) => {
                    state == "prev" ? slidePrev() : slideNext();
                  }}
                  totalRecords={data.teams[tabDetails.selectedTab]?.members}
                  itemsPerSlide={MANAGER_DEFAULT_PAGE_SIZE}
                />
              </div>
            ) : null}
          </div>
        </div>
        {tabDetails.isTabEnabled ? (
          <>
            <div className={styles.tabHolder}>
              {tabDetails &&
                tabDetails.tabData.map((tabInfo, index) => {
                  return (
                    <Tab
                      key={tabInfo.id}
                      label={tabInfo.name}
                      title={tabInfo.name}
                      className={`${styles.tabItem} ${tabInfo.isSelected ? styles.active : ""
                        }`}
                      onClick={() => {
                        handleTab(tabInfo.id, index);
                      }}
                    />
                  );
                })}
            </div>

            <div className={styles.managerMobilenav}>
              <CustomSelectDropDown
                id="dateDropdown"
                text="date"
                isType2={true}
                optionsList={tabDetails.tabData.map((tab, index) => {
                  return `(${data.teams[index].count}) ${tab.name}`;
                })}
                onChange={(value, text) => {
                  handleTab(
                    tabDetails.tabData.filter((tab, index) => {
                      return `(${data.teams[index].count}) ${tab.name}` == text;
                    })[0].id,
                    value
                  );
                }}
                placeholderText="team Name"
              />

            </div>
          </>
        ) : null}
      </div>
      {props.children}
      {!isDesktop && (
        <div className={styles.mobilePaginationSection}>
          {data && data.teams[tabDetails.selectedTab] &&
            data.teams[tabDetails.selectedTab]?.members.length > 0 &&
            data.teams[tabDetails.selectedTab]?.members.length >
            MANAGER_DEFAULT_PAGE_SIZE && (
              <div
                className={`${styles.paginationContainer} ${styles.showMoreLabel}`}
              >
                <Pagination
                  id="manager_direct_reports"
                  createPaginationLabel={(value, maxValue) => {
                    return formatPaginationLabel(
                      globalProperties,
                      value,
                      maxValue
                    );
                  }}
                  label={globalProperties?.load_more}
                  onClick={() => {
                    handlePagination();
                  }}
                  mobile={!isDesktop}
                  maxValue={data.teams[tabDetails.selectedTab]?.members.length}
                  value={endIndex}
                />
              </div>
            )}
        </div>
      )}
    </Fragment>
  );
};

export default ManagerHeader;
