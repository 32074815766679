import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useEffect } from "react";
import { appInsights } from "../../appInsights";
import ContentNotFound from "./ContentNotFound";
import NetworkError from "./NetworkError";
import PageNotFound from "./PageNotFound";
import UnauthroizedAccess from "./UnauthorizedAccess";
import { setLocalStoreValue } from "../../utils/localStore";
import PageUnderDevelopment from "./PageUnderDevelopment";
import APIDowntimeError from "./APIDowntimeError";
import { GraphQLError } from 'graphql';

const ErrorHanding = (props) => {
  
  //const dispatch = useDispatch();
  
  useEffect(() => {
    appInsights.trackException(
      {
        error: new Error(JSON.stringify(props.error)),
        severityLevel: SeverityLevel.Error,
      },
      [props.error]
    );
    if (props.error?.status == 401) {
      setLocalStoreValue("cpath", location.pathname);
      setLocalStoreValue("token", "");
      setLocalStoreValue("tokenExt", "");
      setLocalStoreValue("apigee_token", "");
      setLocalStoreValue("MGMisAuthenticated", "false");

      window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
      /*if (!isElectron()) {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}login`);
      }*/
    }
    try{
      let gqlError = new GraphQLError(props.error);
      let errotStr = gqlError?.toString();
      if(errotStr.indexOf('401')>=0)
      {
        window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
      }
    }
    catch(e)
    {
      
      console.log('e:::',e);
      return <ContentNotFound error={props.error} />;
    }
  }, [props.error]);
  
  if (props.error?.title == "Page under development")
    return <PageUnderDevelopment />;
  if (props.error?.title == "Network Error") return <NetworkError />;
  if (props.error?.status == 404) return <PageNotFound />;
  if (props.error?.status == 403) {
    return <UnauthroizedAccess />;
  }
  if (props.error?.status == 503) {
    //dispatch(authActions.setWorkDayDownState(true));
    return <APIDowntimeError />;
  }
  if (
    props.error &&
    props.error.graphQLErrors &&
    props.error.graphQLErrors[0]
  ) {
    if (
      props.error.graphQLErrors[0]?.extensions?.code ===
        "SERVICE_UNAVAILABLE" ||
      props.error.graphQLErrors[0]?.message?.indexOf("503") > -1
    ) {
      //dispatch(authActions.setWorkDayDownState(true));
      return (
        <APIDowntimeError
          service_key={props.error.graphQLErrors[0]?.extensions?.service_key}
        />
      );
    }
  }
  /*if (props.error?.status != 401) {
    return <ContentNotFound error={props.error} />;
  }*/
};

export default ErrorHanding;
