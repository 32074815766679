import style from "./GlobalSearchBoxMobile.module.css";
import { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchGlobalResultActions } from "../../data/search-global-result-slice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// tsTitle={globalProperties.trending_searches}
// tsData={trendingSearchData}
// debugger; // eslint-disable-line no-debugger

const GlobalSearchBoxMobile = ({ placeholder, globalProperties }) => {
  console.log(globalProperties);
  const [trendingSearchData, setTrendingSearchData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const gqlData = useSelector((state) => state.gqlData);
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  let token = sessionStorage.getItem("token");
  let apigee_token = sessionStorage.getItem("apigee_token");
  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
  };
  let lang = language === undefined || language === "EN" ? "en-us" : language;
  let searchAheadData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assignAutoSuggest = (list) => {
    setSuggestions(list);
  };

  const navigateToSearchPage = (d) => {
    dispatch(searchGlobalResultActions.setsearchClicked(false));
    navigate("/search?type=global&search="+d, {
      state: { trendingDataSelected: d },
    });
  };

  const getSearchAheadData = async (e) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const employeeId = gqlData.profile ? gqlData.profile.employeeId : "";

    await axios
      .get(
        `${baseURL}api/lw-search-ahead?q=${encodeURIComponent(
          e.target.value
        )}&employeeId=${employeeId}&locale=${lang}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        try {
          let suggestName =
            lang === "en-us" ? "mgmSuggestor" : "mgmSuggestor_es";
          searchAheadData =
            response.data.suggest[suggestName][`${e.target.value}`]
              ?.suggestions;
          if (searchAheadData && searchAheadData.length > 0) {
            let result = [];
            searchAheadData.forEach((ele) => {
              let filterStr = ele.term;
              let dropdata = {
                value: filterStr,
              };
              result.push(dropdata);
            });
            setSuggestions(result);
          }
        } catch (e) {
          console.log(e);
        }
      })
      .catch((error) => {
        console.log(error);
        // setError({
        //   title: error.response?.data ? error.response?.data : error.message,
        //   message: error.message ? error.message : error.response?.data,
        //   status: error.response?.status ? error.response?.status : null,
        // });
      });
  };

  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: `Bearer ${apigee_token}`,
      jwt: token,
    };

    axios
      .get(`${baseURL}api/lw-search-trend?locale=${lang}`, {
        headers: headers,
      })
      .then((response) => {
        setTrendingSearchData(
          response?.data?.grouped?.query?.groups?.map((d) => d.groupValue)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      className={style.bgOverlay}
      onClick={() => {
        dispatch(searchGlobalResultActions.setsearchClicked(false));
      }}
    >
      <div
        className={style.searchMobContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={style.containerBg}>
          <div className={style.inputInnerContainer}>
            <SearchInputBar
              placeholder={placeholder}
              navigateToSearchPage={navigateToSearchPage}
              getSearchAheadData={getSearchAheadData}
              assignAutoSuggest={assignAutoSuggest}
            />
            {language != 'tl' && language != 'zh-chs' && <SuggestionBox
              data={suggestions}
              navigateToSearchPage={navigateToSearchPage}
              className={style.sgStyle}
            />}
            {<TrendingSearchBox
              title={globalProperties.trending_searches}
              data={trendingSearchData}
              navigateToSearchPage={navigateToSearchPage}
              className={style.tsStyle}
      />}
          </div>
        </div>
      </div>
    </div>
  );
};

const SearchInputBar = ({
  navigateToSearchPage,
  placeholder,
  getSearchAheadData,
  assignAutoSuggest,
}) => {
  const [assignValue, setAssignValue] = useState();
  const [showClearBtn, setShowClearBtn] = useState(false);
  // const [hideSuggestion, setHideSuggestion] = useState(true); //?
  // console.log(hideSuggestion);

  const delay = 300;
  let timer;

  const onChangeMethod = (e) => {
    if (e.target.value == "") {
      setShowClearBtn(false); //assign should not be blank - to do ?
    }

    setAssignValue(e.target.value);
    setShowClearBtn(true);

    if (e.target.value.length >= 3) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getSearchAheadData(e);
      }, delay);
      // setHideSuggestion(false);
    } else {
      // setSuggestions([]);
      assignAutoSuggest([]);
    }
  };

  return (
    <div>
      <div className={style.inputOuter}>
        <input
          type="text"
          autoFocus
          className={style.input}
          value={assignValue}
          placeholder={placeholder}
          onChange={(e) => {
            onChangeMethod(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              navigateToSearchPage(e.target.value);
            }
          }}
        />
        <div
          className={style.searchIconInside}
          onClick={() => {
            assignValue && navigateToSearchPage(assignValue);
          }}
        />
      </div>
      {showClearBtn && (
        <div
          className={style.clearIcon}
          onClick={() => {
            //reset all
            // setfacetcountForMob(null);
            setAssignValue("");
            // setSearchClick(false);
            // setshowfilter(false);
            // setResultdata([]);
            // setTotalNum(0);
            // settopresult(null);
            // setSuggestions([]);
            assignAutoSuggest([]);

            setShowClearBtn(false);
            // setShowContentLoader(false);
            // setPage(1);
            // settotalPage(0);
            // setShowLoadMore(false);
            // setfilterData([]);
          }}
        ></div>
      )}
    </div>
  );
};

const SuggestionBox = ({ data, navigateToSearchPage, ...props }) => {
  return (
    data.length > 0 && (
      <div
        className={`${style.container} ${style.suggestHeaderBox} ${props.className}`}
      >
        <ul>
          {data.map((obj, index) => {
            return (
              <li
                key={index}
                onClick={(e) => {
                  console.log(e, navigateToSearchPage);
                  navigateToSearchPage(e.currentTarget.innerHTML);
                  // setShowClearBtn(true);
                }}
              >
                {obj.value}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

const TrendingSearchBox = ({ title, data, navigateToSearchPage, ...props }) => {
  return (
    <div className={`${style.container} ${props.className}`}>
      <div className={style.trendingSearchContainer}>
        <h3>{title}</h3>

        <div className={style.itemHolder}>
          {data.map((d, i) => {
            return (
              <div
                key={`trending_${i}`}
                className={style.iconmGlass}
                name={d}
                onClick={() => {
                  navigateToSearchPage(d);
                }}
              >
                {d}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchBoxMobile;
