import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { domActions } from "../../data/dom-slice";
import { navigationActions } from "../../data/navigation-slice";
import useWindowDimensions from "../../hooks/useScreenDetails";
import styles from "./Header.module.css";
import InfoPopup from "./InfoPopup";
import MainNavigation from "./MainNavigation";
//import { authActions } from "../../data/authentication-slice";
import { Icon } from "@mgmresorts/mgm-ui";
import { searchGlobalResultActions } from "../../data/search-global-result-slice";
import WithFetchGlobal from "../../hoc/WithFetchGlobal";
import BookmarkPopup from "./BookmarkPopup";
import CrisisNotification from "./CrisisNotification";
import GlobalSearchBox from "./GlobalSearchBox";
import GlobalSearchBoxMobile from "./GlobalSearchBoxMobile";
import NotificationPopup from "./NotificationPopup";
import SiteMaintenance from "./SiteMaintenance";
// import Stock from "../common/stock/stock";
import { jwtDecode } from "jwt-decode";
// import APIError from "../../pages/ErrorHandling/APIError";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { MY_TASKS_NOTIFICATION } from "../../features/api-wrapper/apiQueries";
import serviceWorker from "../../serviceWorker";
import { useTracking } from "../../utils/customanalytics/TrackingProvider";
import Loader from "./Loader";
import NewHireAccess from "./NewHireAccess";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { getLocalStoreValue } from "../../utils/localStore";
import NatomiChatBot from "../dynamic/NatomiChatBot"

const Header = (props) => {
  const { trackEvent } = useTracking();
  let data = props.data;
  const crisisNotification = data[0]?.data?.crisisNotification || [];
  const dataSiteMaintenance = data[0]?.data?.website_maintenance || [];
  const dataNewHireAccessDelay = data[0].data?.newHireAccessDelay || [];
  const gqlData = useSelector((state) => state.gqlData);
  const showSiteMaintanance = useSelector(
    (state) => state.auth.showSiteMaintanance
  );
  const isWorkDaydown = useSelector((state) => state.auth.workdayApiState);
  const stockState = useSelector((state) => state.dom.stockCompState);
  const isNewHire = useSelector((state) => state.gqlData?.profile?.newHire);
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isComponentVisible, setIsComponentVisible } = useOutsideAlerter(
    headerRef,
    true
  );
  // const headerHeight = useSelector((state) => state.dom.headerHeight);
  const [searchHover, setSearchHover] = useState(false);
  const [bookmarkHover, setBookmarkHover] = useState(false);
  const [notificationHover, setNotificationkHover] = useState(false);
  const [helpHover, setHelpHover] = useState(false);
  const updateCounter = useSelector((state) => state.dom.updateCounter);
  const [toggleNav, setToggleNav] = useState({
    isHamburger: false,
    isFavourites: false,
    isNotification: false,
    isSearch: false,
  });
  const [isMobile] = useWindowDimensions();
  const [showPopover, setShowPopover] = useState(false);
  const [showBookmarkList, setShowBookmarkList] = useState(false);
  const [showNotificationList, setShowNotificationList] = useState(false);
  const [searchToggle, setsearchToggle] = useState(false);
  const pageTitle = useSelector((state) => state.dom.pageAnalyticsTitle);
  const profile = gqlData?.profile;
  let token = getLocalStoreValue("token");
  let user = "";
  let userRoles = "";
  const myTasksData = useSelector((state) => state.dom.myTasks);
  const {
    loading,
    error,
    data: myTasksNotification,
  } = useQuery(MY_TASKS_NOTIFICATION, {
    client: apiMiddlewareClient,
  });

  if (token) {
    user = jwtDecode(token ? token : "");
    userRoles = user?.roles?.join(",");
  }
  const profileInfo = {
    userid: profile?.employeeId,
    employeeType: profile?.payRateGroup,
    businessLocation: profile?.primaryLocation,
    role: profile?.businessTitle,
    employeeRole: userRoles,
    locale: profile?.preferredLanguage,
    parentPageTitle: pageTitle,
  };

  /*useEffect(() => {
    // console.log('test')
    if (profileInfo?.userid) {
      (function()
      { var ns = document.createElement('script');
      ns.src = ('https://chatapps-us.netomi.com/app/script.js');ns.type = 'text/javascript';
      ns.async = true; ns.setAttribute('botRefId', 'c37ffaee-b7e7-4cc1-ad1a-64bcafd84015');
      var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(ns, s);
      // console.log('hihi', profileInfo.userid.toString());
      ns.onload = function () {window.NETOMI_WEB_WIDGET.init(() =>
      {window.NETOMI_WEB_WIDGET.publish('VISITOR_INFO',
      { EMPLOYEE_ID: profileInfo?.userid.toString() })
      })}})();
    } 
    // return () => {
    //   document.body.removeChild(ns);
    // };
}, []);*/

  useEffect(() => {
    if (myTasksNotification) {
      let tasks = myTasksNotification.myTasks.tasks.map((task) => {
        return {
          ...task,
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
        };
      });
      dispatch(
        domActions.setMyTasksStatus({
          ...myTasksNotification,
          myTasks: { ...myTasksNotification.myTasks, tasks: tasks },
        })
      );
    }
  }, [myTasksNotification]);

  const updateCustomAnalyticsEvent = (event) => {
    if (headerRef.current && headerRef.current.contains(event.target)) {
      trackEvent("Click Event", {
        componentName: "Header",
        event: event,
        "event-target": event.target.outerHTML,
        "parent-target": event.target.parentElement.outerHTML,
        ...profileInfo,
      });
    }
  };
  useEffect(() => {
    const handleClick = (event) => {
      if (headerRef.current && headerRef.current.contains(event.target)) {
        trackEvent("Click Event", {
          componentName: "Header",
          event: event,
          "event-target": event.target.outerHTML,
          "parent-target": event.target.parentElement.outerHTML,
          ...profileInfo,
        });
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const getHeaderIcons = (list) => {
    if (list) {
      let icons = {};
      for (let i = 0; i < list.length; i++) {
        if (list[i].type) {
          icons[list[i].type] = { ...list[i] };
        }
      }
      return icons;
    }
  };

  const iconObjects = useMemo(
    () => getHeaderIcons(data[0]?.data?.headerIcons),
    [data[0]?.data?.headerIcons]
  );

  const searchClicked = useSelector(
    (state) => state.searchglobalresult.searchClicked
  );

  const rawResponse = useSelector((state) => state.navigation.rawResponse);
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  useEffect(() => {
    if (navigationArray && navigationArray.length > 0 && globalProperties) {
      navigationArray.map((obj) => {
        // let newObj =
        if (obj.link == "/profile") {
          // obj['label'] = globalProperties?.profile;
          return { ...obj, label: globalProperties?.profile };
        }
        return obj;
      });
    }
  }, [globalProperties]);
  useEffect(() => {
    if (!searchClicked) {
      //may be at other places toggle can be removed
      setsearchToggle(false);
    }
  }, [searchClicked]);

  useEffect(() => {
    if (headerRef.current != null)
      dispatch(domActions.setHeaderHeight(headerRef.current.offsetHeight));
  }, [data, updateCounter]);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener("click", () => {
        setShowPopover(false);
        setShowBookmarkList(false);
        // setShowNotificationList(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!isMobile && !isComponentVisible) {
      setShowNotificationList(false);
      setIsComponentVisible(!isComponentVisible);
    }
  }, [isComponentVisible]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow =
        toggleNav.isHamburger ||
        toggleNav.isFavourites ||
        toggleNav.isNotification ||
        toggleNav.isSearch
          ? "hidden"
          : "visible";
    }
  }, [toggleNav]);

  const toggleNavigation = (value) => {
    // setToggleNav((prev) => !prev);
    if (value == "favourites") {
      setToggleNav({
        isHamburger: false,
        isFavourites: true,
        isNotification: false,
        isSearch: false,
      });
    } else if (value == "hamburger") {
      setToggleNav({
        isHamburger: true,
        isFavourites: false,
        isNotification: false,
        isSearch: false,
      });
    } else if (value == "close") {
      setToggleNav({
        isHamburger: false,
        isFavourites: false,
        isNotification: false,
        isSearch: false,
      });
    } else if (value == "notification") {
      setToggleNav({
        isHamburger: false,
        isFavourites: false,
        isNotification: true,
        isSearch: false,
      });
    }
  };

  // Setting Navigation values into Redux
  const makeNavigationData = (array) => {
    let nav = [];
    for (let i = 0; i < array.length; i++) {
      let navItem = getNavigationAttributes(array[i]);
      if (array[i].Children !== undefined) {
        for (let j = 0; j < array[i].Children.length; j++) {
          let navL2Item = getNavigationAttributes(array[i].Children[j]);
          if (array[i].Children[j].Children !== undefined) {
            for (let k = 0; k < array[i].Children[j].Children.length; k++) {
              let navL3Item = getNavigationAttributes(
                array[i].Children[j].Children[k]
              );
              navL2Item.children.push(navL3Item);
            }
          }
          navItem.children.push(navL2Item);
        }
      }
      nav.push(navItem);
    }
    nav.push(
      {
        link: "/profile",
        label: globalProperties?.profile,
        absoluteLink: "/profile",
        showPage: false,
        showChild: false,
        pageType: "category",
        children: [],
      },
      {
        link: "/edr-menu-upload",
        label: "EDR Menu",
        absoluteLink: "/edr-menu-upload",
        showChild: false,
        showPage: false,
        pageType: "category",
        children: [],
      },
      {
        link: "/manage-my-team/employee-details",
        label: "Employee Details",
        absoluteLink: "/manage-my-team/employee-details",
        showChild: false,
        showPage: false,
        pageType: "category",
        children: [],
      }
    );
    return nav;
  };

  const getNavigationAttributes = (navItem) => {
    let splitArray = navItem.AbsoluteUrl.split("/");
    let link = "/" + splitArray[splitArray.length - 1];
    return {
      link,
      pageUid: navItem.PageUid,
      label: navItem.Title,
      absoluteLink: navItem.AbsoluteUrl,
      showPage: navItem.showPage === "true" ? true : false,
      showChild: navItem.showChild === "true" ? true : false,
      pageType: navItem.pageType,
      // pageExtUrl: navItem.pageExtUrl,
      // denyHourlyWorker: navItem.denyHourlyWorker,
      // denySalaryWorker: navItem.denySalaryWorker,
      children: [],
    };
  };

  const navigationArray = useMemo(
    () =>{
      if(rawResponse && globalProperties){
      return makeNavigationData(rawResponse);
      }
      else{
        return [];
      }
    } ,
    [rawResponse,globalProperties]
  );

  useEffect(() => {
    dispatch(navigationActions.setInitialValues(navigationArray));
  }, [navigationArray]);
  // End of (Setting Navigation values into Redux)

  const closeSearchBox = () => {
    dispatch(searchGlobalResultActions.setsearchClicked(false));
    toggleNavigation("close");
  };

  if (loading) return <Loader />;
  // if (error) return <APIError error={error} />;

  return (
    <header ref={headerRef} data-testid="header1">
      <div className={styles.header} style={{ position: "relative" }}>
        {isMobile && (
          <div className={styles.mobileLeftContainer}>
            {toggleNav.isHamburger ||
            toggleNav.isFavourites ||
            toggleNav.isNotification ||
            toggleNav.isSearch ? (
              <a
                data-testid="hamburger-button"
                className={`${styles.hamburger} ${styles.hamClose}`}
                onClick={() => {
                  toggleNavigation("close");
                }}
              ></a>
            ) : (
              <a
                data-testid="hamburger-button"
                className={styles.hamburger}
                onClick={() => {
                  toggleNavigation("hamburger");
                }}
              ></a>
            )}
            <Link
              className={
                toggleNav.isHamburger ||
                toggleNav.isFavourites ||
                toggleNav.isNotification ||
                toggleNav.isSearch
                  ? styles.visibilityh
                  : styles.visibilityb
              }
              onClick={(event) => {
                updateCustomAnalyticsEvent(event);
                event.stopPropagation();
                setShowNotificationList(false);
                // setShowBookmarkList(!showBookmarkList);
                setShowPopover(false);
                closeSearchBox();
                toggleNavigation("favourites");
              }}
            >
              { iconObjects?.bookmark?.iconFile ? (
                <img src={iconObjects?.bookmark?.iconFile} />
              ) : (
                iconObjects?.bookmark?.icon && (
                  <Icon
                    name="heart"
                    variant="outlined"
                    size="small"
                    role="img"
                    color="#FFFFFF"
                    alt="heart"
                  />
                )
              )}
            </Link>{" "}
            {toggleNav.isFavourites && (
              <BookmarkPopup
                style={{ right: "0", top: "75px" }}
                csData={data[0]?.data?.apiWidget?.header_favorites}
                favClick={() => {
                  toggleNavigation("close");
                }}
              />
            )}
          </div>
        )}
        <div className={styles.logoContainer}>
          <Link
            to={data[0]?.data?.logo?.link}
            className={styles.logoLink}
            onClick={() => {
              closeSearchBox();
            }}
          >
            <img
              src={data[0]?.data?.logo?.logo}
              alt={data[0]?.data?.logo?.altText}
              name={data[0]?.data?.logo?.name}
            />
          </Link>
          {!isMobile && gqlData?.profile?.primaryLocationLogo && (
            <>
              <div className={styles.logoDividor}></div>
              <div className={styles.logoHolder}>
                <img
                  className={styles.propertyLogo}
                  src={gqlData?.profile?.primaryLocationLogo}
                  alt={gqlData?.profile?.primaryLocation}
                />
              </div>
            </>
          )}
        </div>

        {isMobile && (
          <div>
            <div className={styles.mobileRightContainer}>
              <Link
                title="Search"
                className={`${styles.searchIcon}
                  ${
                    toggleNav.isHamburger ||
                    toggleNav.isFavourites ||
                    toggleNav.isNotification ||
                    toggleNav.isSearch
                      ? styles.visibilityh
                      : styles.visibilityb
                  }`}
                onClick={() => {
                  //note: for mobile, no search bar is shown in header

                  //toggle
                  if (!searchToggle) {
                    setsearchToggle(true); //this state may be removed
                    dispatch(searchGlobalResultActions.setsearchClicked(true));
                  } else {
                    setsearchToggle(false);
                    dispatch(searchGlobalResultActions.setsearchClicked(false));
                  }
                }}
              >
                {iconObjects?.search?.iconFile ? (
                  <img src={iconObjects?.search?.iconFile} />
                ) : (
                  iconObjects?.search?.icon && (
                    <Icon
                      name={iconObjects?.search?.icon}
                      variant="outlined"
                      size="small"
                      role="img"
                      color="#FFFFFF"
                      alt={iconObjects?.search?.altText}
                    />
                  )
                )}
              </Link>

              <Link
                className={
                  toggleNav.isHamburger ||
                  toggleNav.isFavourites ||
                  toggleNav.isNotification ||
                  toggleNav.isSearch
                    ? styles.visibilityh
                    : styles.visibilityb
                }
                onClick={(event) => {
                  updateCustomAnalyticsEvent(event);
                  event.stopPropagation();
                  setShowBookmarkList(false);
                  setShowPopover(false);
                  // setShowNotificationList(true);
                  closeSearchBox();
                  toggleNavigation("notification");
                }}
              >
                {iconObjects?.notification?.iconFile ? (
                  <>
                    (
                    <img
                      src={iconObjects?.notification?.iconFile}
                      className={styles.iconFile}
                    />
                    {myTasksData &&
                      myTasksData.myTasks &&
                      myTasksData.myTasks.newItems && (
                        <img
                          className={styles.indicatorMobile}
                          src={iconObjects?.notification?.indicator_icon}
                          alt="indicator icon"
                        />
                      )}
                    )
                  </>
                ) : (
                  <>
                    <Icon
                      name={iconObjects?.notification?.icon}
                      variant="outlined"
                      size="small"
                      role="img"
                      color="#FFFFFF"
                      alt={iconObjects?.notification?.altText}
                    />
                    {myTasksData &&
                      myTasksData.myTasks &&
                      myTasksData.myTasks.newItems && (
                        <img
                          className={styles.indicatorMobile}
                          src={iconObjects?.notification?.indicator_icon}
                          alt="indicator icon"
                        />
                      )}
                  </>
                )}
              </Link>

              {toggleNav.isNotification && (
                <NotificationPopup
                  style={{ right: "0", top: "76px" }}
                  csData={data[0]?.data?.apiWidget?.header_notifications}
                  onClick={() => {
                    closeSearchBox();
                  }}
                  hideNotificationList={() => {
                    toggleNavigation("close");
                  }}
                  notificationClick={() => {
                    toggleNavigation("close");
                  }}
                  {...myTasksData}
                  error={error}
                />
              )}

              {toggleNav.isHamburger && (
                <Link
                  to={iconObjects?.help?.url}
                  className={styles.helpIcon}
                  onClick={() => {
                    closeSearchBox();
                  }}
                >
                  {iconObjects?.help?.iconFile ? (
                    <img src={iconObjects?.help?.iconFile} />
                  ) : (
                    iconObjects?.help?.icon && (
                      <Icon
                        name={iconObjects?.help?.icon}
                        variant="outlined"
                        size="small"
                        role="img"
                        color="#FFFFFF"
                        alt={iconObjects?.help?.altText}
                      />
                    )
                  )}
                </Link>
              )}
            </div>
          </div>
        )}
        {!isMobile && (
          <div
            className={styles.iconContainer}
            style={{ position: "relative" }}
          >
            {searchClicked && (
              <GlobalSearchBox
                placeholder={globalProperties.search_box_placeholder}
                globalProperties={globalProperties}
              />
            )}
            {!searchClicked && (
              <Link
                title={iconObjects?.search?.altText}
                // to="/search"
                className={styles.searchIcon}
                onClick={() => {
                  dispatch(searchGlobalResultActions.setsearchClicked(true));
                }}
              >
                {iconObjects?.search?.iconFile ? (
                  <img src={iconObjects?.search?.iconFile} />
                ) : (
                  iconObjects?.search?.icon && (
                    <Icon
                      name={iconObjects?.search?.icon}
                      variant={searchHover ? "outlined" : "outlined"}
                      size="small"
                      onMouseOver={() => setSearchHover(true)}
                      onMouseOut={() => setSearchHover(false)}
                      role="img"
                      color="#FFFFFF"
                      onHover={iconObjects?.search?.icon}
                      alt={iconObjects?.search?.altText}
                    />
                  )
                )}
                {/* // </div> */}
              </Link>
            )}

            <Link
              title={iconObjects?.help?.altText}
              to={iconObjects?.help?.url}
              className={styles.helpIcon}
              onClick={() => {
                closeSearchBox();
              }}
            >
              {iconObjects?.help?.iconFile ? (
                <img src={iconObjects?.help?.iconFile} />
              ) : (
                iconObjects?.help?.icon && (
                  <Icon
                    name={iconObjects?.help?.icon}
                    variant={helpHover ? "outlined" : "outlined"}
                    onMouseOver={() => setHelpHover(true)}
                    onMouseOut={() => setHelpHover(false)}
                    size="small"
                    role="img"
                    color="#FFFFFF"
                    alt={iconObjects?.help?.altText}
                  />
                )
              )}
            </Link>
            <a
              title={iconObjects?.bookmark?.altText}
              className={
                showBookmarkList ? styles.bookmark : styles.bookmarkNormal
              }
              onMouseOver={() => setBookmarkHover(true)}
              onMouseOut={() => setBookmarkHover(false)}
              onClick={(event) => {
                updateCustomAnalyticsEvent(event);
                event.stopPropagation();
                setShowNotificationList(false);
                setShowBookmarkList(!showBookmarkList);
                setShowPopover(false);
                closeSearchBox();
                serviceWorker();
              }}
            >
              {iconObjects?.bookmark?.iconFile ? (
                <img src={iconObjects?.bookmark?.iconFile} />
              ) : (
                iconObjects?.bookmark?.icon && (
                  <Icon
                    name="heart"
                    variant={bookmarkHover ? "outlined" : "outlined"}
                    size="small"
                    onMouseOver={() => setBookmarkHover(true)}
                    onMouseOut={() => setBookmarkHover(false)}
                    role="img"
                    color={!showBookmarkList ? "#FFFFFF" : "#00000"}
                    alt={iconObjects?.bookmark?.altText}
                  />
                )
              )}
            </a>

            {showBookmarkList && (
              <BookmarkPopup
                style={{ right: "16px", top: "62px" }}
                csData={data[0]?.data?.apiWidget?.header_favorites}
              />
            )}
            <a
              title={iconObjects?.notification?.altText}
              className={
                showNotificationList
                  ? styles.notificationBk
                  : styles.notifiyActual
              }
              onMouseOver={() => setNotificationkHover(true)}
              onMouseOut={() => setNotificationkHover(false)}
              onClick={(event) => {
                updateCustomAnalyticsEvent(event);
                event.stopPropagation();
                setShowBookmarkList(false);
                setShowPopover(false);
                setShowNotificationList(!showNotificationList);
                closeSearchBox();
              }}
            >
              {iconObjects?.notification?.iconFile ? (
                <>
                  <img
                    src={iconObjects?.notification?.iconFile}
                    className={styles.iconFile}
                  />
                  {myTasksData &&
                    myTasksData.myTasks &&
                    myTasksData.myTasks.newItems && (
                      <img
                        className={styles.indicator}
                        src={iconObjects?.notification?.indicator_icon}
                        alt="indicator icon"
                      />
                    )}
                </>
              ) : iconObjects?.notification?.icon ? (
                <>
                  <Icon
                    name={iconObjects?.notification?.icon}
                    variant="outlined"
                    color={!notificationHover ? "#FFFFFF" : "#00000"}
                    size="small"
                    role="img"
                    alt={iconObjects?.notification?.altText}
                  />{" "}
                  {myTasksData &&
                    myTasksData.myTasks &&
                    myTasksData.myTasks.newItems && (
                      <img
                        className={styles.indicator}
                        src={iconObjects?.notification?.indicator_icon}
                        alt="indicator icon"
                      />
                    )}
                </>
              ) : null}
            </a>
            {showNotificationList && (
              <NotificationPopup
                style={{ right: "12px", top: "62px" }}
                csData={data[0]?.data?.apiWidget?.header_notifications}
                onClick={() => {
                  closeSearchBox();
                }}
                hideNotificationList={() => {
                  setShowNotificationList(false);
                }}
                notificationClick={() => {
                  toggleNavigation("close");
                }}
                {...myTasksData}
                error={error}
              />
            )}
            <a
              title={iconObjects?.profile?.altText}
              data-testid="profileIcon"
              className={styles.profileIcon}
              onClick={(event) => {
                updateCustomAnalyticsEvent(event);
                event.stopPropagation();
                setShowPopover(!showPopover);
                setShowBookmarkList(false);
                setShowNotificationList(false);
                closeSearchBox();
              }}
            >
              <span>
                {gqlData.profile
                  ? gqlData.profile.fullName
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()
                  : ""}
              </span>
            </a>
            {showPopover && (
              <InfoPopup
                csData={data[0]?.data?.apiWidget?.header_profile}
                name={gqlData.profile ? gqlData.profile.fullName : ""}
                id={gqlData.profile ? gqlData.profile.employeeId : ""}
                style={{ right: "0", top: "62px" }}
                profileHander={() => {
                  setShowPopover(false);
                }}
              />
            )}
          </div>
        )}
      </div>

      {isMobile && searchClicked && (
        <GlobalSearchBoxMobile
          placeholder={globalProperties.search_box_placeholder}
          globalProperties={globalProperties}
        />
      )}
      {showSiteMaintanance || isWorkDaydown ? (
        <SiteMaintenance data={dataSiteMaintenance}></SiteMaintenance>
      ) : (
        <></>
      )}
      {isNewHire && (
        <NewHireAccess
          newHireAccess={dataNewHireAccessDelay}
          isMaintenAvailable={showSiteMaintanance}
        ></NewHireAccess>
      )}
      {crisisNotification?.length != 0 && (
        <CrisisNotification data={crisisNotification} />
      )}
      {stockState && <div className="stockMobile">{/* <Stock></Stock> */}</div>}
      {isMobile && toggleNav.isHamburger && (
        <div
          // style={{ height: `calc(100vh - ${headerHeight}px)` }}
          className={`${styles.navDropdown} ${toggleNav ? styles.isOpen : ""}`}
        >
          <div
            className={styles.profileBar}
            onClick={() => {
              setToggleNav("close");
              navigate("/profile");
            }}
          >
            <div className={styles.profileIcon}>
              <span>
                {gqlData.profile
                  ? gqlData.profile.fullName
                      .match(/(\b\S)?/g)
                      .join("")
                      .match(/(^\S|\S$)?/g)
                      .join("")
                      .toUpperCase()
                  : ""}
              </span>
            </div>
            <div className={styles.profileName}>
              <p>{gqlData.profile ? gqlData.profile.fullName : ""}</p>
              <p>
                {data[0]?.data?.apiWidget?.header_profile?.employee_id}{" "}
                {gqlData.profile ? gqlData.profile.employeeId : ""}
              </p>
            </div>
          </div>
          <MainNavigation
            isMobile={isMobile}
            csData={rawResponse}
            toggleNavigation={() => {
              toggleNavigation("close");
            }}
          />
          <div
            className={styles.signout}
            onClick={() => {
              /*localStorage.setItem("MGMisAuthenticated", "false");
              dispatch(authActions.setIsAuthendicated(false));
              navigate("/login");*/
              localStorage.removeItem("token");
              localStorage.removeItem("apigee_token");
              localStorage.setItem("MGMisAuthenticated", "false");
              sessionStorage.removeItem("cpath");

              sessionStorage.removeItem("token");
              sessionStorage.removeItem("apigee_token");
              sessionStorage.setItem("MGMisAuthenticated", "false");
              window.location.replace(
                `${process.env.REACT_APP_BASE_URL_LOGIN}logout`
              );
            }}
          >
            <a>{data[0]?.data?.apiWidget.header_profile?.sign_out}</a>
          </div>
        </div>
      )}
      {data[0]?.data?.apiWidget?.netomi_chat_bot && <NatomiChatBot natomiChatbotData={data[0]?.data?.apiWidget?.netomi_chat_bot} profileInfo={profileInfo}/>}
    </header>
  );
};

export default WithFetchGlobal(Header);

