import style from "./FilterAsMobile.module.css";
import { Checkbox, Button,  Radio } from "@mgmresorts/mgm-ui";
import { useState, useEffect } from "react";
import { FILTER_RESULTS} from "../../Const";

const FilterMobile = (props) => {
  const [btnDisable, setbtnDisable] = useState(true);
  const PAGES_DOCUMENT = props.csData.filter(
    (d) => d.name?.toLowerCase() == "filter_criteria_1"
  )[0]?.value;
  const QUICK_LINKS = props.csData.filter(
    (d) => d.name?.toLowerCase() == "filter_criteria_2"
  )[0]?.value;
  //const DEFAULT_OPTION = 'Pages & Documents';
  //const [selectedRadioVal,setSelectedRadioVal] = useState(DEFAULT_OPTION);
  const selectedRadio = props.selectedRadio;
  useEffect(() => {
    setbtnDisable(props.btnState);
  }, [props.btnState]);
  
  const ctrlBtnState = (e) => {
    if (e.target.checked) {
      setbtnDisable(false);
      props.setbtnState(false);
    } else {
      let isatleastonechecked = false;
      props.facetcounts.map((d) => {
        /*if(e.target.name == d.name)
        {
          d.checked = false;
        }*/
        if (d.checked == true) {
          isatleastonechecked = true;
          return;
        }
      });
      if (isatleastonechecked) {
        setbtnDisable(false);
        props.setbtnState(false);
      } else {
        setbtnDisable(true);
        props.setbtnState(true);
      }
    }
  };

  const getVal = (e) => {
    console.log(e.target.name);
    let clickedname = e.target.name;
    //e.target.setAttribute("checked", "checked");
    let returndata;
    let obj = props.facetcounts.map((d) => {
      return d;
    });
    returndata = obj.map((d) => {
      let newObj = {};
      if (d.name === clickedname) {
        //d.checked = !d.checked;
        newObj.checked = !d.checked;
        newObj.name = d.name;
      } else {
        newObj.checked = d.checked;
        newObj.name = d.name;
      }
      return newObj;
    });
    props.setfacetcounts(returndata);
  };

  const updateCount = (filterArr) => {
    if (filterArr && filterArr.length) {
      return filterArr.reduce((t, d) => {
        return d.checked ? t + 1 : t;
      }, 0);
    } else {
      return null;
    }
  };

  const clearFilter = () => {
    props.onClose();
    let returndata;
    returndata = props.facetcounts.map((d) => {
      let newObj = {};
      newObj.name = d.name;
      newObj.checked = false;
      return newObj;
    });
    props.setfacetcounts(returndata);
    props.clearfilter();
    setbtnDisable(true);
    props.setfacetcountForMob(null);
  };

  const applyFilter = () => {
    props.onClose();
    props.filterResult(props.facetcounts);
    props.setfacetcountForMob(updateCount(props.facetcounts));
  };
  const handleOptionChange = (e)=>{
    props.setRadioOption(e.target.value);
    props.radioClicked(true);
  }
  return (
    <div className={style.filterContainer}>
      <div>
        <div className={style.filterHeader}>
          <div className={style.filterTitle}>
            {props.csData
              ? props.csData.filter(
                  (d) => d.name?.toLowerCase() == "filter results"
                )[0]?.value
              : "Filter results"}
          </div>
          <div className={style.closeBtn} onClick={props.onClose} />
        </div>
        <div className={style.filterResultContent}>
            {props.csData
            ? props.csData.filter(
                (d) => d.name?.toLowerCase() == "filter_results_desc"
              )[0]?.value
            : FILTER_RESULTS}
          </div>
        <div className= {`radioHolder ${style.mholder}`}>
        <Radio
          name="options"
          checked={selectedRadio == PAGES_DOCUMENT}
          onChange={(e) => handleOptionChange(e)}
          value={PAGES_DOCUMENT}
          label={PAGES_DOCUMENT}
          labelPosition="left"
          variant="small"          
        />
          {selectedRadio == PAGES_DOCUMENT && <div className={style.inputH}>
            {props.facetcounts &&
              props.facetcounts.map((c, index) => (
                <div
                  key={index}
                  className={`${style.checkHolder} ${
                    c.checked ? style.checkedHolder : null
                  }`}
                >
                  <label htmlFor={index} className={`${style.cLable}`}>
                    {c.name}
                  </label>
                  <Checkbox
                    testId=""
                    key={index}
                    id={index}
                    variant="small"
                    onClick={getVal}
                    name={c.name}
                    onChange={ctrlBtnState}
                    checked={c.checked}
                    className="filterCheckbox"
                  />
                </div>
              ))}
          </div>}
          <Radio
          name="options"
          checked={selectedRadio== QUICK_LINKS}
          onChange={(e) => handleOptionChange(e)}
          value={QUICK_LINKS}
          label={QUICK_LINKS}
          labelPosition="left"
          variant="small"
        />
        </div>
      </div>

      {selectedRadio == PAGES_DOCUMENT && <div className={style.filterBottom}>
        <hr />
        <div className={style.filterControlH}>
          <div className={style.filterClear} >
            {/* <TextLink variant="small">
              {props.csData
                ? props.csData.filter(
                    (d) => d.name?.toLowerCase() == "clear filters"
                  )[0].value
                : "Clear filters"}
            </TextLink> */}
             <Button
             onClick={clearFilter}
              label={
                props.csData
                ? props.csData.filter(
                    (d) => d.name?.toLowerCase() == "clear filters"
                  )[0].value
                : "Clear filters"
              }
              size="small"
              variant="secondary"
              // disabled={btnDisable}
              className="clearFilterBtn"
            />
          </div>
          <div className={style.filterApply}>
            <Button
              onClick={applyFilter}
              label={
                props.csData
                  ? props.csData.filter(
                      (d) => d.name?.toLowerCase() == "apply filters"
                    )[0].value
                  : "Apply filters"
              }
              size="small"
              variant="primary"
              disabled={btnDisable}
              className="filterApplyBtn"
            />
          </div>
        </div>
      </div>}
    </div>
  );
};

export default FilterMobile;
