import SearchInputGlobal from "./SearchInputGlobal";
import { useNavigate } from "react-router-dom";
import styles from "./AgentSearch.module.css";
import arrowLeft from "../../assets/images/chevron-left-aligned-left.svg";
import WithFetchData from "../../hoc/WithFetchData";
import { getLocalStoreValue } from "../../utils/localStore";

const GlobalSearch = (props) => {
  const navigate = useNavigate();

  return (
    <div className={styles.globalSearch}>
      <div>
        <div className={styles.backBtn} onClick={() => {
          navigate(getLocalStoreValue("searchBackPath"))
        }}>
          <img src={arrowLeft} />
          {
            props.data[0]?.data?.dictionary?.filter((d) => d.name == "back")[0]
              .value
          }
        </div>
        <div className={styles.searchInputContainer}>
          <div className={styles.searchInputWidth}>
            <SearchInputGlobal {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithFetchData(GlobalSearch);
