import styles from "./Login.module.css";
import {  useState } from "react";
//import { useEffect, useState } from "react";
//import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {  useDispatch } from "react-redux";
import { authActions } from "../../data/authentication-slice";
//import { useQuery } from "@apollo/client";
import { TextInput, Button } from "@mgmresorts/mgm-ui";

import HeaderLogin from "../../components/global/HeaderLogin";
import FooterLogin from "../../components/global/FooterLogin";
//import { GET_LOGIN } from "../../features/contentstack/graphqlQueries";
import axios from "axios";
import { setLocalStoreValue } from "../../utils/localStore";

/*const logindata = [
  {
    empid: "test",
    pass: "test-123",
  },
  {
    empid: 12345,
    pass: 12345,
  },
  {
    empid: 54321,
    pass: 54321,
  },
];*/

const SwitchUser = () => {
  const dispatch = useDispatch();
  //const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  const navigate = useNavigate();

  //const navigate = useNavigate();
  //const [searchParams] = useSearchParams();
  
  /*useEffect(() => {
    
    if (
      isAuthenticated ||
      //localStorage.getItem("MGMisAuthenticated") === "true"
      sessionStorage.getItem("MGMisAuthenticated") === "true"
    ) {
      navigate("/dashboard");
    }
    else if(searchParams.get('token'))
    {
      navigate("/dashboard");
      //localStorage.setItem("MGMisAuthenticated", "true");
      sessionStorage.setItem("token", searchParams.get('token'));
      sessionStorage.setItem("MGMisAuthenticated", "true");
      dispatch(authActions.setIsAuthendicated(true));
    }
    else if(oktaLogin == "true")
    {
      //navigate("/dashboard");
      window.location.replace('https://eh-middleware.azurewebsites.net/login')
      
    }
  }, []);*/

  /* {
    "employeeId": 585741,
    "password": "12345"
}*/
  //const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lSWQiOiI1ODU3NDEiLCJyb2xlcyI6WyJIb3VybHkiXSwid29ya2VySWQiOiI0YjVkN2NhNGNlZWQxMDBlNWM4M2ViODFiNWQwMDAwMCIsIm5hbWUiOiJBbmR5IFRlc3QiLCJpYXQiOjE2OTUwMTkxMDQsImF1ZCI6ImVtcGxveWVlaHViIGNsaWVudCIsImlzcyI6ImVtcGxveWVlaHViIiwic3ViIjoiZW1wbG95ZWVodWIgYXV0aCJ9.BMoWl9-XQkUK98v5Z_hPm9oFPYfbwoAokP74gFzHCoLKXh-tYiKkE82mWANoFWcU4pgVgndIrdartpBOLf-aoluhg-kooxaZ_wx1mUwH0A_hlP7Nut8gJ44iOrTOTBeXFH39rMgzzxV14_sRTjSvGk6DFFTUdjDTODa5Ugp3sa8P9LlFfpX2vsfOCqMX29XG8HyQvNFqHSpNK80HLXsnjm4vix8e6aa7yBhFhP2Ag8bSb_7I5ORSJvbOj71ssdOCUzB3jQv0NKKpbPnwbn6MxqJbT9IGeyRLCJLLulbdtNFLqbfzxT15PWAFNbmxIn9m4OF8dqcNmbkrHhXKjZc0pA";
  const token = sessionStorage.getItem("token");
  /*
  const { loading, error, data } = useQuery(GET_LOGIN);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  */

  const login_header = "Welcome to My MGM"; //data.all_login.items[0].body.welcome_text;
  const login_empplaceholder = "Employee ID"; //data.all_login.items[0].body.field_labels[0];
  const login_passplaceholder = "Password"; //data.all_login.items[0].body.field_labels[1];
  const login_btn = "Sign in"; //data.all_login.items[0].body.button_link.title;
  /*
  const login_header =
    data.all_login.items[0].body.welcome_text;
  const login_empplaceholder =
    data.all_login.items[0].body.field_labels[0];
  const login_passplaceholder =
    data.all_login.items[0].body.field_labels[1];
  const login_btn =
    data.all_login.items[0].body.button_link.title;
    */

  const onSubmit = (e) => {
    e.preventDefault();

    axios.post(`${process.env.REACT_APP_BASE_URL}api/switch-user`, {
      employeeId: username,
      password: password
    },{
      headers: {
        jwt: token
      }
    })
    .then(function (response) {
      console.log('response>>>',response);
      setLocalStoreValue("token", response.data.token);
      setLocalStoreValue("tokenExt", response.data.tokenExt);
      setLocalStoreValue("apigee_token", response.data.apigee_token);
      setLocalStoreValue("MGMisAuthenticated", "true");
      dispatch(authActions.setIsAuthendicated(true));
      navigate("/dashboard");
    })
    .catch(function (error) {
      console.log('error>>>',error);
    });
    /*setUsername("");
    setPassword("");
    const usercheck = logindata.find(
      (user) => user.empid == username && user.pass == password
    );
    if (usercheck) {
      navigate("/dashboard");
      
      sessionStorage.setItem("MGMisAuthenticated", "true");
      dispatch(authActions.setIsAuthendicated(true));
    } */
  };

  //if (isAuthenticated) return;

  const usernameChangeHandle = (e) => {
    setUsername(e.target.value);
  };

  const passwordChangeHandle = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <HeaderLogin />
      <main className={styles.mainBox}>
        <form className={styles.loginForm} onSubmit={onSubmit}>
          <h2>{login_header}</h2>
          <TextInput
            id="input_username"
            onChange={usernameChangeHandle}
            value={username}
            label={login_empplaceholder}
          />
          <TextInput
            id="input_password"
            label={login_passplaceholder}
            variant="password"
            onChange={passwordChangeHandle}
            value={password}
          />
          <Button
          className={styles.submitBtn}
            label={login_btn}
            size="large"
            fullWidth={true}
            variant="primary"
            type="submit"
          />
        </form>
      </main>
  <FooterLogin />
    </>
  );
};

export default SwitchUser;
