import styles from "./LetterNotFound.module.css";


const LetterNotFound = (props) => {
  
  console.log('error',props);

  return (
    <>
      <div className={`${styles.eholder}`}>
      
        <div
          className={`${styles.APIErrContainer}`}
        >
          <div className={`${styles.iconholder}`}></div>
          <div className={styles.txtH}>
            {/*<div className={styles.txt1}>{props?.error?.status}</div>*/}
            <div
              className={styles.txt2}
            >{props?.error?.letter_not_available_text}</div>
          </div>
        </div>
      
    </div>

    </>
  );
};

export default LetterNotFound;
