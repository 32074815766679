import {setLocalStoreValue} from './localStore'
const handleTokenExpiry = (error) => {
    console.log('error>>>',error);
    if (error && JSON.stringify(error).includes("status code 401")) {
        reset();
    }
    else if (error && error.status && error.status == 401) {
        reset();
    }
}
const reset = () => {
    setLocalStoreValue("cpath", location.pathname);
    setLocalStoreValue("token", "");
    setLocalStoreValue("tokenExt", "");
    setLocalStoreValue("apigee_token", "");
    setLocalStoreValue("MGMisAuthenticated", "false");
    
    window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
}
export { handleTokenExpiry }