import { useSelector } from "react-redux";
import styles from "./GlobalFlyoutMobile.module.css";
// import { Icon } from "@mgmresorts/mgm-ui";
import { useRef, useState, useEffect } from "react";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "../dynamic/InnerHtml";
import Modal from "./ui/Modal";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

const GlobalFlyoutMobile = () => {
  const globalFlyoutMenu = useSelector((state) => state.dom.flyOutMenu);
  const { menuList, mobileMenuIcon, desktopMenuIcon, menuTitle } =
    globalFlyoutMenu?.data || [];
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();
  const [isCollapsed, setIsCollpsed] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  let idleTimeout = useRef(null);
  const myRef = useRef(null);
  const { isComponentVisible, setIsComponentVisible } = useOutsideAlerter(
    myRef,
    true
  );

  useEffect(() => {
    if (!isComponentVisible) {
      setIsCollpsed(false);
      setIsComponentVisible(!isComponentVisible);
    }
  }, [isComponentVisible]);

  const openModal = (args) => {
    let modal = menuList[args]?.cta?.modalContent
      ? menuList[args]?.cta?.modalContent
      : menuList[args]?.cta?.modal_content;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
    setIsButtonVisible(true);
    handleUserActivity();
  };

  const handleUserActivity = () => {
    clearTimeout(idleTimeout.current);
    if (isButtonVisible) {
      setIsButtonVisible(true);
    }
    idleTimeout.current = setTimeout(() => {
      setIsButtonVisible(false);
    }, 2000);
  };

  const handleHideWidget = () => {
    document.body.querySelector('div[data-test="netomichat"]').style.display = "none";
  }
  const  handleShowWidget = () => {
    document.body.querySelector('div[data-test="netomichat"]').style.display = "block";
  }

  const handleNetomiChat =  () => {
    const netomiChat = true;
    if (netomiChat && !isCollapsed) {
      handleHideWidget();
    } else if (netomiChat && isCollapsed) {
      handleShowWidget();
    }
  }

  const handleClick = () => {
    setIsCollpsed(!isCollapsed);
    handleNetomiChat();
  }

  useEffect(() => {
    const eventTypes = [
      "mousemove",
      "scroll",
      "keydown",
      "keypress",
      "keyup",
      "touchstart",
      "touchmove",
    ];
    eventTypes.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });
    handleUserActivity();
    return () => {
      eventTypes.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
      clearTimeout(idleTimeout.current);
    };
  }, []);

  return (
    <>
     <div>
      {isCollapsed && (
        <div
          className={styles.overlay}
          onClick={() => {
            setIsCollpsed(false)
            handleShowWidget(true)
          }} 
        ></div>
      )}
      <div className={styles.globalFlyoutWrapperMob}>
        <div className={styles.globalFlyoutContainerMob} ref={myRef}>
          {(
            <button
              className={`${styles.accordionArrowMob} ${isButtonVisible ? styles.visible : ''}`}
              onClick={() => {
                handleClick();
                // setIsCollpsed(!isCollapsed);
              }}
              role="button"
              aria-describedby="click to collapse"
            >
              {" "}
              {menuTitle}
              <img
                src={desktopMenuIcon?.url}
                alt=""
                className={`${styles.menuIconsMob} ${
                  isCollapsed ? styles.open : null
                }`}
              />
            </button>
          )}
          {(
            <ul
              className={`${styles.flyoutMenuMob} ${
                isCollapsed ? styles.start : styles.end
              }`}
            >
              <li
                className={styles.actionPanelContainer}
                onClick={() => {
                handleClick();
                  // setIsCollpsed(!isCollapsed);
                }}
              >
                <button className={styles.actionPanel}>
                  <h6>{menuTitle}</h6>{" "}
                  <img
                    src={mobileMenuIcon?.url}
                    alt=""
                    className={`${styles.menuIconsForActionsMob} ${
                      isCollapsed ? styles.open : null
                    }`}
                  />
                </button>
              </li>
              {menuList &&
                menuList.length > 0 &&
                menuList.map((val, index) => {
                  return (
                    <li
                      key={val.uid}
                      className={styles.globalFlyoutSectionMob}
                      onClick={() => {
                        setIsCollpsed(!isCollapsed);
                      }}
                    >
                      <ChooseCta
                        size="small"
                        iconLeft={val.cta?.leading_icon}
                        iconRight={val.cta?.trailing_icon}
                        type="text_link"
                        args={index}
                        openModal={openModal}
                        data={val.cta}
                      />
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
      </div>
    </>
  );
};
export default GlobalFlyoutMobile;
