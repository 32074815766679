/* eslint no-undef:0 */
import styles from "./NatomiChatBot.module.css";
import { useEffect, useState, useRef } from "react";
import { getLocalStoreValue } from "../../utils/localStore";
import chatIcon from "../../assets/chat.svg";
import useWindowDimensions from "../../hooks/useScreenDetails";

const NatomiChatBot = (props) => {
  const botRefId = props?.natomiChatbotData?.botRefId;
  //const type = props?.natomiChatbotData?.type;
  const url = props?.natomiChatbotData?.url;
  const userId = props?.profileInfo?.userid;
  // const [viewportSize, setViewportSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });
  // const [isMobileChat] = useWindowDimensions(1025);
  const [isMobileChat] = useWindowDimensions(1025);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  let tokenExt = getLocalStoreValue("tokenExt");
  const refNetomi = useRef();
  let idleTimeout = useRef(null);
  // useEffect(() => {
  // const handleResize = () => {
  //   setViewportSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });
  // };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    // console.log('test')
    if (userId && url && botRefId && tokenExt && !window.NETOMI_WEB_WIDGET) {
      (function () {
        var ns = document.createElement("script");
        // ns.src = (url);ns.type = 'text/javascript';
        ns.src = url;

        ns.async = true;
        ns.setAttribute("botRefId", botRefId);

        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(ns, s);

        ns.onload = function () {
          window.NETOMI_WEB_WIDGET.init(() => {
            authenticate();
            Netomi.chat.hideSupportIcon();
            document.getElementById("startChat").style.display = "flex";

            if (isMobileChat) {
              Netomi.chat.showCalloutBubble({ calloutBubbleEnabled: false });
            } else {
              Netomi.chat.showCalloutBubble({
                calloutBubbleEnabled: true,
                attachments: [
                  { type: "Text", text: "Hi!" },
                  {
                    type: "Text",
                    text: "I'm your My MGM Virtual Assistant.",
                    quickReply: { options: [] },
                  },
                ],
                delayForFirstBubble: 500,
                delayInBetweenBubbles: 500,
                url: "",
              });
            }

            // chatIframe = window.document.getElementById("netomiChatWindow");
          });

          window.NETOMI_WEB_WIDGET.onLoad(() => {
            document.getElementById("netomiChatWindow").style.height = 0;
            /* Netomi.chat.hideWidget();
             refNetomi.current.style.display = "block";*/
          });
          window.Netomi.chat.subscribe("MINIMIZE_CHAT_WIDGET", (data) => {
            console.log("data", data);
            setTimeout(() => Netomi.chat.hideWidget(), 0);
            document.getElementById("startChat").style.display = "flex";
            //refNetomi.current.style.display = "block";
          });
          window.Netomi.chat.subscribe("CHAT_END_PILL", (data) => {
            console.log("data", data);
            //document.getElementById('startChat').style.display = 'flex';
            //Netomi.chat.hideWidget();
            //refNetomi.current.style.display = "block";
          });
          window.Netomi.chat.subscribe("END_CHAT_CONFIRM", (data) => {
            console.log("data", data);
            //document.getElementById('startChat').style.display = 'flex';
            //setTimeout(() => Netomi.chat.hideWidget(), 0);
            //refNetomi.current.style.display = "block";
          });
          window.Netomi.chat.subscribe("SESSION_EXPIRE_PILL", (data) => {
            console.log("data", data);
            document.getElementById("startChat").style.display = "flex";
            Netomi.chat.hideWidget();
            //refNetomi.current.style.display = "block";
          });
          window.Netomi.chat.subscribe("SURVEY_SUBMITTED", (data) => {
            console.log("data", data);
            Netomi.chat.hideWidget();
            document.getElementById("startChat").style.display = "flex";
            //refNetomi.current.style.display = "block";
          });
          window.Netomi.chat.subscribe("SURVEY_SKIPPED", (data) => {
            console.log("data", data);
            Netomi.chat.hideWidget();
            document.getElementById("startChat").style.display = "flex";
            //refNetomi.current.style.display = "block";
          });
        };
      })();
    }
    function authenticate() {
      Netomi.chat.authenticate(async (sendTokenToNetomi) => {
        const token = tokenExt;
        sendTokenToNetomi(token);
      });
    }
  }, [userId, url, botRefId]);

  // useEffect(() => {
  //   console.log("viewportSize.width ", viewportSize.width);
  //   if (viewportSize.width < 1025) {
  //     initChatPos();
  //   }
  // }, [viewportSize]);

  const handleUserActivity = () => {
    clearTimeout(idleTimeout.current);
    if (isButtonVisible) {
      setIsButtonVisible(true);
      // window.Netomi.chat.showWidget();
    }
    idleTimeout.current = setTimeout(() => {
      setIsButtonVisible(false);
    }, 2000);
  };

  useEffect(() => {
    const eventTypes = [
      "mousemove",
      "scroll",
      "keydown",
      "keypress",
      "keyup",
      "touchstart",
      "touchmove",
    ];
    eventTypes.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });
    handleUserActivity();
    return () => {
      eventTypes.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
      clearTimeout(idleTimeout.current);
    };
  }, []);

  // const initChatPos = () => {
  //   setTimeout(() => {
  //     chatIframe = window.document.getElementById("netomiChatWindow");
  //     console.log("viewportSize.width chatIframe ", chatIframe);
  //     if (chatIframe) {
  //       chatIframe.style.bottom = "90px";
  //       chatIframe.style.opacity = "1";
  //     }
  //   }, 2000);
  // };

  function startChat() {
    // console.log("asdasd", refNetomi.current);
    document.getElementById("startChat").style.display = "none";
    setTimeout(() => {
      Netomi.chat.showWidget();
      Netomi.chat.openWidget();
    }, 500);
  }

  return (
    <div className={styles.chatWrapper} data-test={"netomichat"}>
      {isButtonVisible || !isMobileChat ? (
        <div
          id="startChat"
          className={styles.customNetomiChat}
          onClick={startChat}
          ref={refNetomi}
        >
          <img className={styles.chatIcon} src={chatIcon} />
        </div>
      ) : (
        <div
          id="startChat"
          className={styles.customNetomiChatHide}
          onClick={startChat}
          ref={refNetomi}
        >
          <img className={styles.chatIcon} src={chatIcon} />
        </div>
      )}
    </div>
  );
};
export default NatomiChatBot;


