import { CardContainer, Tab } from "@mgmresorts/mgm-ui";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./QuickSnapshot.module.css";
import useMediaQuery from "../../../hooks/useMediaQuery";
// import DirectReporteeTimeOff from "./DirectReporteeTimeOff"; // not in use, old design
import DirectReporteeTimeOffNew from "./DirectReporteeTimeOffNew";
import DirectReporteeLOA from "./DirectReporteeLOA";

const QuickSnapshot = (props) => {
  const { dictionaryLables } = props.data;
  const isDesktop = useMediaQuery("screen and (min-width: 1024px)");
  const { state } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  const tabTitles = Object.keys(dictionaryLables)
    .filter((key) => key.startsWith("tab") && key.endsWith("_title"))
    .map((key) => dictionaryLables[key]);
  
  // const handleOptionChange = (e) => {
  //   console.log(e.target.value);
  //   setTabIndex(parseInt(e.target.value));
  // }

  return (
    <>
      <div>
        <CardContainer className={styles.cardContainer}>
          {/* {isDesktop ? ( */}
            <div className={styles.cardContainerInner}>
              {tabTitles.map((title, index) => (
                <div key={index} className={styles.tabHolder}>
                  {index === 0 ? (
                    <Tab
                      label={title}
                      className={`${styles.active} ${index === tabIndex ? styles.currActive : ""}`}
                      tabIndex="-1"
                      onClick={() => setTabIndex(index)}
                    />
                  ): 
                  <Tab
                    label={title}
                    className={`${styles.active} ${index === tabIndex ? styles.currActive : ""}`}
                    tabIndex="-1"
                    onClick={() => setTabIndex(index)}
                  />
                }
                </div>
              ))}
            </div>
          {/* ) : (
            <div className={styles.mobilenav}>
              <select className={styles.dropdownDisabled} onChange={handleOptionChange}>
                <option value={0}>{dictionaryLables.tab1_title}</option>
                <option value={1}>{dictionaryLables.tab2_title}</option>
              </select>
            </div>
          )} */}

          {tabIndex === 0 ? (
            <DirectReporteeTimeOffNew memberId={state} data={props.data} isDesktop={isDesktop} tabTitle={tabTitles[0]}/>
          ) : (
            <>
            <DirectReporteeLOA data={props.data} memberId={state} isDesktop={isDesktop} tabTitle={tabTitles[0]}/>
            {/* <div>
              <div className={styles.comingSoonContainer}>
                {dictionaryLables && dictionaryLables.coming_soon_title}
              </div>
            </div>
            <div className={styles.springText}>
              {dictionaryLables && dictionaryLables.coming_soon_message_title}
            </div>
            <div className={styles.springDescription}>
              {dictionaryLables && dictionaryLables.coming_soon_message}
            </div> */}
          </>
          )}
        </CardContainer>
      </div>
    </>
  );
};

export default QuickSnapshot;
