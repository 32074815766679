import { useState, useEffect } from "react";
import { CardContainer, CenterModal, TextLink, Tab } from "@mgmresorts/mgm-ui";
import styles from "./ManagerTasksToDo.module.css";
import {
  GET_MANAGER_TASK_TODO,
  GET_MANAGER_TASK_TODO_CELEBRATIONS,
} from "../../../features/api-wrapper/apiQueries";
import { useQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import Loader from "../../../components/global/Loader";
import APIError from "../../ErrorHandling/APIError";
import { useSelector } from "react-redux";
import { TASK_TODO_DEFAULT_TASKS } from "../../../Const";
import useWindowDimensions from "../../../hooks/useScreenDetails";
// import useMediaQuery from "../../../hooks/useMediaQuery";

const ManagerTasksToDo = (props) => {
  const [isMobile] = useWindowDimensions(1025);
  const [taskTodoModalOpen, setTaskTodoModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { dictionaryLables, linkDictionaryLables } =
    props.data;
  // const isDesktop = useMediaQuery("screen and (min-width: 1024px)");



  const {
    loading: tasksLoading,
    error: tasksError,
    data: taskTodoModalData,
  } = useQuery(GET_MANAGER_TASK_TODO, {
    client: apiMiddlewareClient,
  });

  const {
    loading: celebrationsLoading,
    error: celebrationsError,
    data: taskTodoCelebrationsData,
  } = useQuery(GET_MANAGER_TASK_TODO_CELEBRATIONS, {
    client: apiMiddlewareClient,
  });


  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const tasks = taskTodoModalData?.mmtTasksToDo?.todos || [];
  const totalCount = taskTodoModalData?.mmtTasksToDo?.totalCount || 0;
  const celebrations =
    taskTodoCelebrationsData?.mmtGetTeamCelebrations?.data || [];

  const celebrationTotalCount = celebrations.length;
  const paginationLabel = globalProperties.pagination_label;
  const formatPaginationLabel = (template, visibleCount, totalCount) => {
    return template
      .replace('<span translate="no">{variable-noofpage}</span>', visibleCount)
      .replace('<span translate="no">{variable-totalnoofrecords}</span>', totalCount);
  };

  useEffect(() => {
    try {
      if (!isMobile) {

        document.getElementById('fiftyFifty').style.display = 'table';
        document.getElementById('fiftyFifty').style.height = 'fit-content';
        //document.getElementById('fiftyFifty').style.borderCollapse = 'separate';
        //document.getElementById('fiftyFifty').style.borderSpacing = '48px';
        document.getElementById('actionpanel').style.display = 'table-cell';
        document.getElementById('actionpanel').style.width = '50%';
        document.getElementById('tasktodo').style.display = 'table-cell';
        document.getElementById('tasktodo').style.width = '50%';

      }
      else {
        document.getElementById('fiftyFifty').style.display = 'flex';
        document.getElementById('fiftyFifty').style.flexDirection = 'column';
        document.getElementById('actionpanel').style.display = 'block';
        document.getElementById('actionpanel').style.width = '100%';
        document.getElementById('tasktodo').style.display = 'block';
        document.getElementById('tasktodo').style.width = '100%';

      }
    }
    catch (e) {
      console.log(e);
    }




  }, [isMobile])

  // const openModal = () => {
  //   setTaskTodoModalOpen(true);
  // };

  const closeModal = () => {
    setTaskTodoModalOpen(false);
  };

  const taskTodoData = (isPopup = false) => {
    const records = activeTab === 0 ? tasks : celebrations;
    const limitedRecords = isPopup
      ? records
      : records.slice(0, TASK_TODO_DEFAULT_TASKS);

    return limitedRecords.map((record, index) => {
      if (activeTab === 1) {
        const suffix = record.year ? `${record.year} Year` : "";
        const title = suffix
          ? `${suffix} ${record.type.charAt(0).toUpperCase() + record.type.slice(1)} Celebration`
          : `${record.type.charAt(0).toUpperCase() + record.type.slice(1)} Celebration`;
        return (
          <TaskItem
            key={index}
            title={title}
            clickable={false}
            memberName={record.employee}
            dateInitiated={record.date}
          />
        );
      }
      return (
        <TaskItem
          key={index}
          title={record.title}
          memberName={record.memberName}
          message={record.message || ""}
          clickable={true}
          dateInitiated={record.dateInitiated}
        />
      );
    });
  };

  const handleTaskClick = () => {
    let path = linkDictionaryLables?.take_action_url?.link?.href;
    window.open(path, "_blank");
  };

  const tabTitles = [
    `${dictionaryLables?.tab1_title} (${totalCount})`,
    `${dictionaryLables?.tab2_title} (${celebrationTotalCount})`,
  ];

  if (tasksLoading || celebrationsLoading) return <Loader />;

  const TaskItem = ({ title, memberName, message, dateInitiated,clickable }) => {
    const formattedDate = dateInitiated.replace(/-/g, '/');
    return (
      <div className={clickable?styles.tasskItem:styles.celebrationTask} onClick={clickable?handleTaskClick:null}>
        <div>
          <h4 className={styles.taskTitle}>{title}</h4>
          <p className={styles.taskName}>{memberName}</p>
          {message && <p className={styles.taskDuration}>{message}</p>}
        </div>
        <div className={styles.taskMeta}>
          <p className={styles.taskDate}>{formattedDate}</p>
          {/* {activeTab === 0 && (
            <div className={styles.taskMenu}>
              <img
                className={styles.menuIcon}
                src={dictionaryWithIconLables?.take_action?.url}
              />
            </div>
          )} */}
        </div>
      </div>
    );
  };
  const handleTabChange = (index) => {
    setActiveTab(index);
  };


  return (
    <><CardContainer id='tasktodo' className={styles.managerTaskTodoContainer}>
      {/* {isDesktop ? ( */}
      <div className={styles.cardContainerInner}>
        {tabTitles.map((title, index) => (
          <div
            key={index}
            className={`${styles.tabHolder} ${activeTab === index ? styles.active : styles.inactive
              }`}
            onClick={() => handleTabChange(index)}
          >
            <Tab label={title} tabIndex="-1" />
          </div>
        ))}
      </div>
      {/* // ) : (
        //   <><div className={styles.mobilenav}>
        //     <select
        //       onChange={(event) => handleTabChange(parseInt(event.target.value))}>
        //       <option value="0" selected={activeTab == 0}>
        //           {`${dictionaryLables?.tab1_title} (${totalCount})`}
        //         </option>

        //         <option value="1" selected={activeTab == 1}>
        //           {`${dictionaryLables?.tab2_title} (${celebrationTotalCount})`}
        //         </option>
        //     </select>

        //   </div><div className={styles.mobileHeading}>
        //       <h2>{`${activeTab === 0 ? dictionaryLables.tab1_title : "Celebrations"} (${activeTab === 0 ? totalCount : celebrationTotalCount})`}</h2>
        //     </div></>
        // )} */}
      {activeTab == 0 && tasksError ? (
        <APIError error={tasksError} />
      ) : activeTab == 1 && celebrationsError ? (
        <APIError error={celebrationsError} />
      ) : (
        <>
          {activeTab === 0 && totalCount === 0 ? (
            <div
              className={styles.centerContent}>
              <h2>{dictionaryLables?.tab1_no_records_message1}</h2>
              <p>{dictionaryLables.tab1_no_records_message2}</p>
            </div>
          ) : activeTab === 1 && celebrationTotalCount === 0 ? (
            <div className={styles.centerContent}>
              <h2>{dictionaryLables.tab2_no_records_message1}</h2>
              <p>{dictionaryLables.tab2_no_records_message2}</p>
            </div>
          ) : (
            <>
              {/* <div className={styles.showMoreLabel}>
                {activeTab === 0
                  ? formatPaginationLabel(paginationLabel, `${Math.min(4, totalCount ?? 0)}`, `${totalCount ?? 0}`)
                  : formatPaginationLabel(paginationLabel, `${Math.min(
                    4,
                    celebrationTotalCount ?? 0)}`, `${celebrationTotalCount ?? 0}`)}
              </div> */}
              {activeTab===0 &&totalCount>4?
               <div className={styles.cardContainerShowmore}>
              <div className={styles.showMoreLabel}>
                {formatPaginationLabel(paginationLabel, `${Math.min(4, totalCount ?? 0)}`, `${totalCount ?? 0}`)}
                </div><TextLink
                className={styles.viewAll}
                href={linkDictionaryLables?.view_all?.link.href}
                target="_blank"
                // onClick={() => openModal()}
              >
                {linkDictionaryLables?.view_all?.link.title}
              </TextLink></div>:""}
              {activeTab===1 &&celebrationTotalCount>4?
               <div className={styles.cardContainerShowmore}>
              <div className={styles.showMoreLabel}>
                {formatPaginationLabel(paginationLabel, `${Math.min(
                    4,
                    celebrationTotalCount ?? 0)}`, `${celebrationTotalCount ?? 0}`)}
                </div><TextLink
                className={styles.viewAll}
                href={linkDictionaryLables?.view_all?.link.href}
                target="_blank"
                // onClick={() => openModal()}
              >
                {linkDictionaryLables?.view_all?.link.title}
              </TextLink></div>:""}
            </>
          )}
          {taskTodoData()}
        </>
      )}

      <CenterModal
        title={`${activeTab === 0 ? dictionaryLables.tab1_title : "Celebrations"
          } (${activeTab === 0 ? totalCount : celebrationTotalCount})`}
        open={taskTodoModalOpen}
        size="large"
        visibleScrollBar={true}
        onClose={closeModal}
      >
        {taskTodoData(true)}
      </CenterModal>
    </CardContainer>{!isMobile && <div className={styles.buffer}></div>}</>
  );
};

export default ManagerTasksToDo;
