import style from "./FileIcon.module.css";

const FileIcon = ({ fileType, icons }) => {
  if (!fileType || !icons) return null; // Handle missing data

  const fileKey = fileType[0].includes("excel")
    ? "excel"
    : fileType[0].includes("word")
    ? "word"
    : fileType[0].includes("powerpoint")
    ? "ppt"
    : fileType[0].includes("pdf")
    ? "pdf"
    : fileType[0].split("/")[1];

  const matchingIcon = icons.find((icon) =>
    icon.name.toLowerCase().includes(fileKey)
  );
  const defaultIcon = icons.find((icon) =>
   icon.name.toLowerCase().includes('search_default_document_icon')
);

  const iconUrl = matchingIcon?.icon?.url? matchingIcon?.icon?.url:defaultIcon?.icon?.url;

  return (
    <img src={iconUrl} alt={`${fileKey} icon`} className={style.fileIcon} />
  );
};

export default FileIcon;
