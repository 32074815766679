import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { appInsights } from "../../appInsights";
//import { isElectron, setLocalStoreValue } from "../../utils/localStore";
import { useState } from "react";
import { setLocalStoreValue } from "../../utils/localStore";
import APIDowntimeError from "./APIDowntimeError";
import styles from "./APIError.module.css";
//import { authActions } from "../../data/authentication-slice";
import { GraphQLError } from 'graphql';
import LetterNotFound from "./LetterNotFound";

const APIError = (props) => {
  const { error, isRow } = props;
  const globalNotification = useSelector((state) => state.globalNotification);
  const [errorType, setErrorType] = useState();
  //const dispatch = useDispatch();
  
  useEffect(() => {
    if (globalNotification.systemErrors) {
      const { systemErrors } = globalNotification || {};
      const pageNotFound = systemErrors.filter((err) => {
        return err.type == "something_went_wrong";
      });
      setErrorType(pageNotFound[0]);
    }
  }, [globalNotification]);
  appInsights.trackException(
    {
      error: new Error(JSON.stringify(error)),
      severityLevel: SeverityLevel.Error,
    },
    [props.error]
  );
  const redirect = ()=>{
      setLocalStoreValue("cpath", location.pathname);
      setLocalStoreValue("token", "");
      setLocalStoreValue("apigee_token", "");
      setLocalStoreValue("tokenExt", "");
      setLocalStoreValue("MGMisAuthenticated", "false");
      
      window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
  }
  useEffect(() => {
    
    if (props.error?.status == 401) {
      redirect();
      /*setLocalStoreValue("cpath", location.pathname);
      setLocalStoreValue("token", "");
      setLocalStoreValue("apigee_token", "");
      setLocalStoreValue("MGMisAuthenticated", "false");
      
      window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
      if (!isElectron()) {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}login`);
      }*/
    }
    else
    {
      
    try{
      let gqlError = new GraphQLError(props.error);
      let errotStr = gqlError?.toString();
      console.log(' ### props.error ###',props.error);
      console.log(' ### props.error.status ###',props.error.status);
      console.log(' ### props.error ###',errotStr);
      console.log(' ### props.error ###',errotStr.indexOf('401'));
      if(errotStr.indexOf('401')>=0)
      {
        redirect();
      }
    }
    catch(e)
    {
      console.log('e:::',e);
    }
    }
    
  }, [props.error]);
  if (props.error?.status == 503) {
    //dispatch(authActions.setWorkDayDownState(true));
    if(props.error?.title?.service_key)
    {
      return <APIDowntimeError service_key={props.error?.title?.service_key}/>;
    }
    else{
      return <APIDowntimeError />;
    }
    
  }
  else if(props.error?.status == 404 && props.error.compName == 'letter') {
    return <LetterNotFound error={props.error}/>
  }
  //console.log('error ###',error.graphQLErrors[0]);
  if (error && error.graphQLErrors && error.graphQLErrors[0]) {
    if (
      error.graphQLErrors[0]?.extensions?.code === "SERVICE_UNAVAILABLE" ||
      error.graphQLErrors[0]?.message?.indexOf("503") > -1
    ) {
      //dispatch(authActions.setWorkDayDownState(true));
      return <APIDowntimeError  service_key={props.error.graphQLErrors[0]?.extensions?.service_key}/>;
    }
  }
  return (
    <div className={`${styles.eholder}`}>
      {errorType && (
        <div
          className={`${
            isRow ? styles.APIErrRowContainer : styles.APIErrContainer
          }`}
        >
          <div className={`${styles.iconholder}`}></div>
          <div className={styles.txtH}>
            <div className={styles.txt1}>{errorType.title}</div>
            <div
              className={styles.txt2}
              dangerouslySetInnerHTML={{ __html: errorType.description }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default APIError;
