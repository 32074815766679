import { useMutation } from "@apollo/client";
import DOMPurify from "dompurify";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { domActions } from "../../data/dom-slice";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { USER_HAS_READ_MESSAGE } from "../../features/api-wrapper/apiQueries";
import useWindowDimensions from "../../hooks/useScreenDetails";
import APIError from "../../pages/ErrorHandling/APIError";
import CTATextLink from "../dynamic/CTATextLink";
import styles from "./NotificationPopup.module.css";

const NotificationPopup = (props) => {
  const { csData, myTasks } = props;
  const dispatch = useDispatch();
  const [isMobile] = useWindowDimensions();
  const [selectedData, setSelectedData] = useState();
  const [mutateNotification, { data: notifyResponse, error }] = useMutation(
    USER_HAS_READ_MESSAGE,
    { client: apiMiddlewareClient }
  );

  const onTaskClicked = (data) => {
    setSelectedData(data);
    if (!data.isRead) {
      mutateNotification({
        variables: {
          messageId: data.timeReceived,
        },
      });
    } else {
      props.hideNotificationList();
      routeChange();
    }
  };

  useEffect(() => {
    if (
      notifyResponse &&
      notifyResponse.userHasReadMessage &&
      notifyResponse.userHasReadMessage.status == 200
    ) {
      let updatedTasks = myTasks.tasks.map((task) => {
        if (task.id === selectedData.id) {
          return { ...task, isRead: true };
        }
        return task;
      });
      const isAllRead = updatedTasks.every((obj) => obj.isRead);
      let data = {
        myTasks: {
          ...myTasks,
          newItems: !isAllRead,
          tasks: updatedTasks,
        },
      };
      dispatch(domActions.setMyTasksStatus(data));
      props.hideNotificationList();
      routeChange();
    }
  }, [notifyResponse]);

  const routeChange = () => {
    let path = csData?.view_all?.href;
    window.open(path, "_blank");
  };

  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      {props.error ? (
        <div
          className={`${styles.container} ${
            isMobile ? styles.setMWidth : styles.setDWidth
          }`}
          style={props.style}
        >
          <APIError error={props.error} />
        </div>
      ) : (
        <div
          className={`${styles.container} ${
            isMobile ? styles.setMWidth : styles.setDWidth
          }`}
          style={props.style}
        >
          <div className={styles.notificationHeader}>
            <h3 className={`text-left ${styles.notificationH3}`}>
              {csData?.my_tasks ? csData.my_tasks : null}
            </h3>
            <CTATextLink
              size="small"
              style={`${styles.notifyLink} text-right`}
              iconLeft={csData?.view_all?.leadingIcon?.icon}
              iconRight={csData?.view_all?.trailingIcon?.icon}
              type="text_link"
              data={{
                label: csData?.view_all ? csData?.view_all.title : "",
                url: csData?.view_all?.href,
                openIn: csData?.view_all?.openIn,
              }}
            />
          </div>
          <div className={styles.scrollingContainer}>
            {myTasks && myTasks.tasks?.length > 0 ? (
              myTasks.tasks.map((notify) => {
                const due = notify.dueOn;
                const todaySDate = new Date(
                  new Date().toISOString().slice(0, 10)
                );
                const currentDiff = new Date(due) - todaySDate;
                const dayMilliSeconds = 1000 * 60 * 60 * 24;
                const totalDays = currentDiff / dayMilliSeconds;
                const duedate = Math.ceil(totalDays);
                let timeReceived = "";
                if (notify.dueOn) {
                  timeReceived = moment
                    .utc(notify.dueOn.split("T")[0])
                    .format("MM/DD/YYYY");
                }
                const yesterday = moment
                  .utc()
                  .subtract(1, "d")
                  .format("MM/DD/YYYY");
                const isYesterday = timeReceived === yesterday;
                const isToday =
                  timeReceived === moment.utc().format("MM/DD/YYYY");
                return (
                  <div
                    key={notify.id}
                    className={`${styles.notificationRow} ${
                      !notify.isRead ? styles.isReadItem : null
                    }`}
                    data-testid="tasks"
                    onClick={() => {
                      onTaskClicked(notify);
                    }}
                  >
                    <div className={`${styles.notificationItem}`}>
                      <h4>{notify.taskMessage}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(notify.taskDescription),
                        }}
                        className={styles.notifyDescription}
                      />
                      {duedate == 0 && (
                        <div className={styles.taskdate_default}>
                          {`${csData?.due} ${csData.today}`}
                        </div>
                      )}
                      {duedate < 0 && (
                        <div className={styles.taskdate_overdue}>
                          {csData?.overdue}
                        </div>
                      )}
                      {duedate >= 1 && (
                        <div className={styles.taskdate_default}>
                          {`${csData?.due_in} ${duedate} ${
                            duedate == 1 ? csData?.day : csData?.days
                          }`}
                        </div>
                      )}
                    </div>
                    <div className={styles.timeReceived}>
                      {isYesterday
                        ? csData.yesterday
                        : isToday
                        ? csData.today
                        : timeReceived}
                    </div>
                  </div>
                );
              })
            ) : (
              <p className={styles.noNotification}>
                {csData?.no_notifications ? csData.no_notifications : null}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationPopup;
