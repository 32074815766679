import styles from "./DirectReporteeTimeOffNew.module.css";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_TIME_OFF_BALANCES } from "../../../features/api-wrapper/apiQueries";
import APIError from "../../../pages/ErrorHandling/APIError";
import { useLazyQuery } from "@apollo/client";

const DirectReporteeTimeOffNew = (props) => {
  const [searchParams] = useSearchParams();

  const [getTimeOffBalances, { loading, error, data }] = useLazyQuery(
    GET_TIME_OFF_BALANCES,
    {
      client: apiMiddlewareClient,
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (props?.memberId?.memberID) {
      getTimeOffBalances({
        variables: {
          memberId: props.memberId.memberID,
        },
      });
    } else {
      let memberId = searchParams.get("memberID");
      getTimeOffBalances({ variables: { memberId: memberId } });
    }
  }, []);
  const hoursTaken6MonthsText = props.data?.dictionaryLables?.hours_taken_text;
  const availableText = props.data?.dictionaryLables?.available;
  const formatDecimal = (numString) => {
    if (numString === ".00") {
      return ".0";
    }
      
    const num = parseFloat(numString);
    if (!isNaN(num)) {
      return num % 1 === 0 ? num.toFixed(1) : num.toString();
    }
    return numString;
  }

  if (loading) return <Loader />;
  if (error) {
    return (
      <div className={styles.personalInfoCard}>
        <APIError error={error} />
      </div>
    );
  } else {
    return (
      <div>
        {data?.mmtGetTimeOffBalanceAndPastSixMonths &&
          !data?.mmtGetTimeOffBalanceAndPastSixMonths?.isPastSixMonthsDataHidden && (
            <div className={styles.timeOffHeader}>
                <div>{parseInt(data.mmtGetTimeOffBalanceAndPastSixMonths.PastSixMonthsData.data.units).toFixed(1)}</div>
                <div>{hoursTaken6MonthsText}</div>
            </div>
          )}

        {data?.mmtGetTimeOffBalanceAndPastSixMonths &&
          data?.mmtGetTimeOffBalanceAndPastSixMonths?.timeOffBalanceData.map((item, index) => (
            <div key={index} className={styles.timeOffBalanceLine}>
              <div className={styles.timeOffBalanceName}>{item.timeOffPlanName}</div>
              <div className={styles.timeOffBalanceArea}>
                {/* if a whole integer, add one decimal place, else, keep number as is (some have two, but default should all have at least tenth place) */}
                <span>{(parseInt(item.timeOffBalance) % 1 === 0) ? (formatDecimal(item.timeOffBalance)) : item.timeOffPlanName} </span>
                <span>{availableText}</span>
              </div>
            </div>
          ))}
      </div>
    );
  }
};
export default DirectReporteeTimeOffNew;
