import styles from "./Login.module.css";

import { useEffect, useState } from "react";
//import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../data/authentication-slice";
//import { useQuery } from "@apollo/client";
import { TextInput, Button } from "@mgmresorts/mgm-ui";

import HeaderLogin from "../../components/global/HeaderLogin";
import FooterLogin from "../../components/global/FooterLogin";
//import { GET_LOGIN } from "../../features/contentstack/graphqlQueries";
import { setLocalStoreValue, getLocalStoreValue } from "../../utils/localStore";

const { api } = window;
const logindata = [
  {
    empid: "test",
    pass: "test-123",
  },
  {
    empid: 12345,
    pass: 12345,
  },
  {
    empid: 54321,
    pass: 54321,
  },
];

const Login = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  //let electron = false;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let oktaLogin = process.env.REACT_APP_OKTA_LOGIN;
  console.log("oktaLogin>>>", typeof oktaLogin);
  useEffect(() => {
    try {
      // ipcRenderer.on becomes api.recieve
      api.recieve("electron-renderer", (args) => {
        //handle on download-progress event
        console.log("electron-renderer called---", args);
        //electron = true;
        localStorage.setItem("electron", "electron");
      });
    } catch (error) {
      console.log(error);
    }
    console.log("token >>>>>", searchParams.get("token"));
    console.log("electron >>>>>", getLocalStoreValue("electron"));
    const isElectron = getLocalStoreValue("electron") == "electron";

    if(searchParams.get("tokenExt"))
    {
      setLocalStoreValue("tokenExt", searchParams.get("tokenExt"));
    }
    
    if (searchParams.get("token")) {
      //setLocalStoreValue("MGMisAuthenticated", "true");
      setLocalStoreValue("token", searchParams.get("token"));
      setLocalStoreValue("apigee_token", searchParams.get("apigee"));
      setLocalStoreValue("MGMisAuthenticated", "true");
      dispatch(authActions.setIsAuthendicated(true));

      if (isElectron) {
        navigate("/employeeId");
      } else if (getLocalStoreValue("clockout") == "true") {
        navigate("/hourlyclockedout");
      } else if (getLocalStoreValue("uploadedr") == "true") {
        navigate("/edr-menu-upload");
      } else {
        let cpath = getLocalStoreValue("cpath");

        if (cpath && cpath != "" && cpath != "/") {
          navigate(cpath);
        } else {
          navigate("/dashboard");
        }
      }
    } else if (oktaLogin == "true") {
      //navigate("/dashboard");
      //window.location.replace('https://eh-middleware.azurewebsites.net/login')
      window.location.replace(`${process.env.REACT_APP_BASE_URL_LOGIN}login`);
    }
  }, []);

  /*
  const { loading, error, data } = useQuery(GET_LOGIN);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  */

  const login_header = "Welcome to My MGM"; //data.all_login.items[0].body.welcome_text;
  const login_empplaceholder = "Employee ID"; //data.all_login.items[0].body.field_labels[0];
  const login_passplaceholder = "Password"; //data.all_login.items[0].body.field_labels[1];
  const login_btn = "Sign in"; //data.all_login.items[0].body.button_link.title;

  const onSubmit = (e) => {
    e.preventDefault();
    setUsername("");
    setPassword("");
    const usercheck = logindata.find(
      (user) => user.empid == username && user.pass == password
    );
    if (usercheck) {
      setLocalStoreValue("MGMisAuthenticated", "true");
      dispatch(authActions.setIsAuthendicated(true));
      if (getLocalStoreValue("electron") == "electron") {
        navigate("/employeeId");
      } else if (getLocalStoreValue("clockout") == "true") {
        navigate("/hourlyclockedout");
      } else if (getLocalStoreValue("uploadedr") == "true") {
        navigate("/uploadEDR");
      } else {
        navigate("/dashboard");
      }
    }
  };

  if (isAuthenticated) return;

  const usernameChangeHandle = (e) => {
    setUsername(e.target.value);
  };

  const passwordChangeHandle = (e) => {
    setPassword(e.target.value);
  };

  return oktaLogin == "true" ? (
    <></>
  ) : (
    <>
      <HeaderLogin />
      <main className={styles.mainBox}>
        <form className={styles.loginForm} onSubmit={onSubmit}>
          <h2>{login_header}</h2>
          <TextInput
            id="input_username"
            onChange={usernameChangeHandle}
            value={username}
            label={login_empplaceholder}
          />
          <TextInput
            id="input_password"
            label={login_passplaceholder}
            variant="password"
            onChange={passwordChangeHandle}
            value={password}
          />
          <Button
            className={styles.submitBtn}
            label={login_btn}
            size="large"
            fullWidth={true}
            variant="primary"
            type="submit"
          />
        </form>
      </main>
      <FooterLogin />
    </>
  );
};

export default Login;
