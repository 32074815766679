import { Button, Icon, TextLink } from "@mgmresorts/mgm-ui";
import InnerHtml from "../dynamic/InnerHtml";
import styles from "./CrisisNotification.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { domActions } from "../../data/dom-slice";
//import useWindowDimensions from "../../hooks/useScreenDetails";

const CrisisNotification = ({ data }) => {
  const [crisisData] = data;
  const HEIGHT = 100;
  const dispatch = useDispatch();
  const [crisisHeight, setCrisisHeight] = useState(0);
  const [isOverHeighted, setIsOverHeighted] = useState(false);
  const [isShowMoreEnabled, setIsShowMoreEnabled] = useState(false);
  const [isCrisisClosed, setIsCrisisClosed] = useState(false);
  const [isExpanded, setIsExpanded]= useState(false);
  //const [isMobile] = useWindowDimensions();
  const crisisRef = useRef(null);
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
console.log(" setIsShowMoreEnabled(true);",setIsShowMoreEnabled)

  useEffect(() => {
    const onResize = () => {
      if (crisisRef.current != null && !isShowMoreEnabled) {
        
        setCrisisHeight(crisisRef.current.scrollHeight);
      }
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [data, isShowMoreEnabled]);

  useEffect(() => {
    
    setIsOverHeighted(crisisHeight > HEIGHT);
  }, [crisisHeight]);

  const toggleContentHandle =(e)=>{
    e.preventDefault();
    setIsExpanded((prevState)=> !prevState);
    dispatch(domActions.setUpdateCounter())
  }
  const buttonClickHandler = () => {
    sessionStorage.setItem("crisisNotificationDisabled", "true");
    setIsCrisisClosed(true);
    dispatch(domActions.setUpdateCounter());
  };

  useEffect(() => {
    dispatch(domActions.setUpdateCounter());
  }, [isOverHeighted]);

  if (
    sessionStorage.getItem("crisisNotificationDisabled") === "true" ||
    isCrisisClosed
  )
    return;

  return (
    <section
      className={`${styles.crisisNotification} ${styles[crisisData?.type]} ${
        isOverHeighted && !isShowMoreEnabled ? styles.heightRestricted : ""
      }`}
    >
      <div className={styles.crisisNotificationInner}>
        <div
          ref={crisisRef}
          className={`${styles.crisisNotificationContent} ${
            !isOverHeighted || isShowMoreEnabled ? styles.center : ""
          }`}
          style={{
        height: isExpanded ?'100px' : 'auto',
        overflow: 'hidden',
      }}
        >
          <div className={styles.leftContent}>
            <div className={styles.heading}>
              {crisisData?.icon?.icon_file ? (
                <img
                  src={crisisData?.icon?.icon_file?.url}
                />
              ) : (
                crisisData?.icon?.icon && (
                  <Icon
                    name={crisisData?.icon?.icon}
                    variant="filled"
                    size="small"
                    role="img"
                    color={crisisData?.type == "orange" ? "#00012B" : "#ffffff"}
                  />
                )
              )}
              <h3>{crisisData?.heading}</h3>
            </div>
            {crisisData?.description && (
              <InnerHtml
                description={crisisData?.description}
                style={styles.innerHtml}
                hash={crisisData?.$?.description}
              />
            )}
          </div>
          <div className={`${styles.buttonHolder} `}>
            <Button
              data-cslp={null}
              label={crisisData.button_text}
              size="small"
              variant="secondary"
              inverted={crisisData?.type == "orange" ? "" : "inverted"}
              onClick={() => buttonClickHandler()}
            />
          </div>
        </div>
        {isOverHeighted && (
          <div className={styles.showMoreHolder}>
            <div className={styles.shade}></div>
            <TextLink
              data-cslp=""
              href="#"
              onClick={toggleContentHandle}        
              variant="small"
              inverted={crisisData?.type == "orange" ? "" : "inverted"}
            // iconRight="chevron-down"
              >
              {/*isMobile && !isExpanded ? globalProperties?.show_less_button_label : globalProperties?.show_more_button_label */ }
              {/* {globalProperties?.show_more_button_label} */}
              {!isExpanded ? globalProperties?.show_less_button_label : globalProperties?.show_more_button_label  }
            </TextLink>
          </div>
        )}
      </div>
 
    </section>
  );
};

export default CrisisNotification;
