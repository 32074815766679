// import { useSelector } from "react-redux";
import styles from "./TrendingTopicsGroup.module.css";
import { useEffect, useState } from "react";

import TrendingTopic from "./TrendingTopic";
import { LegacyCarousel } from "@mgmresorts/mgm-ui";
// import AliceCarousel from "react-alice-carousel";

const TrendingTopicsGroupRedesign = (props) => {

  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);
  const [disableNavigation, setDisableNavigation] = useState(false);

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    if (deviceSize <= 899) {
      setIsMobile(true);
    } else if (deviceSize > 900) {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);


  const filterTrendingData = props?.data?.trendingTopics?.filter((current) => {
    if (current != undefined) {
      return current;
    }
  });

  useEffect(() => {
    if (filterTrendingData?.length < 4) {
      setDisableNavigation(true);
    } else {
      setDisableNavigation(false);
    }
  }, [props?.data?.trendingTopics]);

  return (
    <>
      <div
        className={styles.cardContainerInner}
      >
        {!isMobile ? (
          <LegacyCarousel
            header={props?.data?.heading}
            headingLevel={"h3"}
            className={styles.trendingCarousel}
            step={"page"}
            spacing={24}
            hiddenArrows={disableNavigation}
            mobile={false}
          >
            {props?.data?.trendingTopics?.map((item, index) => {
              return <TrendingTopic data={item} key={index} />;
            })}
          </LegacyCarousel>
        ) : (
          <LegacyCarousel
            header={props?.data?.heading}
            headingLevel={"h3"}
            className={styles.trendingCarousel}
            step={"page"}
            spacing={0}
            hiddenArrows={true}
            swipeAmount={50}
            swipeEnabled
            mobile
          >
            {props.data?.trendingTopics?.map((item, index) => {
              return <TrendingTopic data={item} key={index} />;
            })}
          </LegacyCarousel>
        )}
      </div>
    </>
  );
};

export default TrendingTopicsGroupRedesign;
