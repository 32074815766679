import { useSelector } from "react-redux";
import styles from "./GlobalFlyout.module.css";
import { useEffect, useRef, useState } from "react";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "../dynamic/InnerHtml";
import Modal from "./ui/Modal";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

const GlobalFlyout = () => {
  const globalFlyoutMenu = useSelector((state) => state.dom.flyOutMenu);
  const { menuList, desktopMenuIcon } = globalFlyoutMenu?.data || [];
  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();
  const [isCollapsed, setIsCollpsed] = useState(false);
  const myRef = useRef(null);
  const { isComponentVisible, setIsComponentVisible } = useOutsideAlerter(
    myRef,
    true
  );

  useEffect(() => {
    if (!isComponentVisible) {
      setIsCollpsed(false);
      setIsComponentVisible(!isComponentVisible);
    }
  }, [isComponentVisible]);

  const openModal = (args) => {
    let modal = menuList[args]?.cta?.modalContent
      ? menuList[args]?.cta?.modalContent
      : menuList[args]?.cta?.modal_content;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className={styles.globalFlyoutWrapper}>
        <ul
          className={styles.globalFlyoutContainer}
          ref={myRef}
          style={{
            maxWidth: isCollapsed ? "300px" : "48px",
            minWidth: isCollapsed? "200px":"48px",
            transition: "width 0.5s ease-in-out",
            marginBottom: '0'
          }}
        >
          <li
            className={styles.accordionArrow}
            onClick={() => {
              setIsCollpsed(!isCollapsed);
            }}
            role="button"
            aria-describedby="click to collapse"
          >
            <img
              src={desktopMenuIcon?.url}
              alt=""
              className={`${styles.menuIcons} ${
                isCollapsed ? styles.open : null
              }`}
            />
          </li>
          {menuList &&
            menuList.length > 0 &&
            menuList.map((val, index) => {
              return (
                <li
                  key={val.uid}
                  className={styles.globalFlyoutSection}
                  data-testid="flyoutClick"
                  onClick={() => {
                    setIsCollpsed(false);
                  }}
                  title={val.cta.label}
                >
                  <ChooseCta
                    size="small"
                    iconLeft={val.cta?.leading_icon}
                    iconRight={val.cta?.trailing_icon}
                    type="text_link"
                    args={index}
                    openModal={openModal}
                    data={val.cta}
                  />
                </li>
              );
            })}
        </ul>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};
export default GlobalFlyout;
