import MultiSelection from "../multiselection/MultiSelection";
import InnerHtml from "../../dynamic/InnerHtml";
import { useSelector, useDispatch } from "react-redux";
import CustomSelectDropDownWithIcon from "../../common/customSelectDropDown/customSelectDropdownWithIcon";
import { Button, Icon } from "@mgmresorts/mgm-ui";
import styles from "./SubmitRecognitionForm.module.css";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import APIError from "../../../pages/ErrorHandling/APIError";
import Loader from "../../global/Loader";
import ConfirmationPopup from "../ConfirmPrompt";
import { recognitionActions } from "../../../data/recognition-slice";

const SubmitRecognitionForm = (props) => {

  const { isManagerProfile, fullName, employeeIdData } = props;
  const submitRecognition = gql`
    mutation SubmitRecognition(
      $submitRecognitionInput: SubmitRecognitionInput!
    ) {
      submitRecognition(submitRecognitionInput: $submitRecognitionInput) {
        statusCode
        statusMessage
        errorCode
        errorType
        errorMessage
      }
    }
  `;

  const formData = props.data ? props.data[0] : {};

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [fieldEdited, setFieldEdited] = useState(false);
  const filterNameValPair = (name) => {
    let returnObj = {};
    returnObj = formData?.data?.nameValue.filter((obj) => {
      return obj.name == name;
    });
    return returnObj;
  };
  const finish_label = filterNameValPair("finish_label")
    ? filterNameValPair("finish_label")[0].value
    : "";
  const recognize_more_label = filterNameValPair("recognize_more_label")
    ? filterNameValPair("recognize_more_label")[0].value
    : "";
  const recognition_success_heading = filterNameValPair(
    "recognition_success_heading"
  )
    ? filterNameValPair("recognition_success_heading")[0].value
    : "";
  const recognition_success_message = filterNameValPair(
    "recognition_success_message"
  )
    ? filterNameValPair("recognition_success_message")[0].html_value
    : "";

  const multiple_user_pronoun = filterNameValPair('multiple_user_pronoun') ? filterNameValPair('multiple_user_pronoun')[0].value : '';
  const single_user_pronoun = filterNameValPair('single_user_pronoun') ? filterNameValPair('single_user_pronoun')[0].value : '';
  const recognition_success_heading_icon =
    props.data[0]?.data.nameValue[2].icon.url;
  const badgeData = useSelector((state) => state.auth.badgeData);
  const [isAllFieldPopulated, setAllFieldPopulated] = useState(false);
  const formNameValuePair = formData?.data?.nameValue;
  const [comment, setComment] = useState("");
  const [badgeRef, setBadgeRef] = useState();
  const [badgeName, setBadgeName] = useState();
  const [formSubmissionDone, setFormSubmissionDone] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorData, setErrorData] = useState();
  //const [successData, setSuccessData] = useState();  
  let cancelLabel;
  let nextLabel;
  let dateToday;
  const [formatedNames, setFormatedNames] = useState();
  const [employees, setEmployees] = useState();
  const [employeeNames, setEmployeeNames] = useState();
  const [
    submitFormMutation,
    { data: updateSubmitRecognitionInfo, loading, error },
  ] = useMutation(submitRecognition, { client: apiMiddlewareClient });


  const dispatch = useDispatch();

  useEffect(() => {

    if (formSubmissionDone == true) {
      setComment('');
      setBadgeRef('');
      setEmployees('');

      props.handleFieldEdited(false);
    }
    formSubmissionDone &&
      dispatch(recognitionActions.setRecognitionSubmited(true));
  }, [formSubmissionDone]);

  useEffect(() => {

    let pronoun = employeeNames?.length > 1 ? `${multiple_user_pronoun}` : `${single_user_pronoun}`;
    if (employeeNames) {
      setFormatedNames(
        recognition_success_message
          .replace('{variable-name}', employeeNames.map(String).join(","))
          .replace('{variable-badgename}', badgeName)
          .replace('{variable-pronouns}', pronoun)
      );
    }
    if (isManagerProfile) {
      setFormatedNames(
        recognition_success_message
          .replace('{variable-name}', fullName)
          .replace('{variable-badgename}', badgeName)
          .replace('{variable-pronouns}', pronoun));
    }
    if (
      updateSubmitRecognitionInfo &&
      updateSubmitRecognitionInfo?.submitRecognition?.statusCode == 200
    ) {
      setFormSubmissionDone(true);
      setSuccess(true);
      //let successObj = {};
      //setSuccessData(successObj);
      //props.handleModalData(props.data[0]?.data);
      //props.onClose(true);

    } else if (
      updateSubmitRecognitionInfo &&
      updateSubmitRecognitionInfo?.submitRecognition?.errorCode != null &&
      updateSubmitRecognitionInfo?.submitRecognition?.errorCode != ""
    ) {
      setFormSubmissionDone(true);
      setValidationError(true);
      let errorObj = {};
      errorObj.errorType =
        updateSubmitRecognitionInfo?.submitRecognition?.errorType;
      errorObj.errorMessage =
        updateSubmitRecognitionInfo?.submitRecognition?.errorMessage[0];
      setErrorData(errorObj);
    }
  }, [updateSubmitRecognitionInfo]);
  formNameValuePair.map((obj) => {
    if (obj.name == "cancel_button_label") {
      cancelLabel = obj.value;
    }
    if (obj.name == "submit_button_label") {
      nextLabel = obj.value;
    }
  });
  const handleClose = () => {
    props.onClose(true);
  };
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const getBadges = () => {
    let returnVal = [];
    if (
      badgeData &&
      badgeData.payload &&
      badgeData.payload[0] &&
      badgeData.payload[0].data &&
      badgeData.payload[0].data.badges
    ) {
      badgeData.payload[0].data.badges.map((obj) => {
        returnVal.push({ name: obj.value, value: obj.name, icon: obj.icon });
      });
    }


    return returnVal;
  };
  useEffect(() => {
    if (isContinue) {
      //props.setModal(false);
      handleClose();
      setIsModalOpen(false);
      setIsContinue(false);
      //document.body.style.overflow = "visible";
    }
  }, [isContinue]);
  useEffect(() => {
    if (isManagerProfile) {
      if (
        (badgeRef && badgeRef != "") ||
        (comment && comment != "")
      ) {
        setFieldEdited(true);
        props.handleFieldEdited(true);
      }
      else {
        props.handleFieldEdited(false);
      }
    }
    if (isManagerProfile) {

      if (
        (badgeRef && badgeRef != "") &&
        (comment && comment != "")
      ) {
        
        dateToday = formatDate(new Date());

        setAllFieldPopulated(true);
      }
      else {
        setAllFieldPopulated(false);
      }

    } 
    
    else{
      if (
        employees &&
        employees.length > 0 &&
        badgeRef &&
        badgeRef != "" &&
        comment &&
        comment != ""
      ) {
        dateToday = formatDate(new Date());
  
        setAllFieldPopulated(true);
      }
      else {
        setAllFieldPopulated(false);
      }

    }
    
  }, [employees, comment, badgeRef]);
  if (loading) {
    return <Loader />;
  }
  if (error) {
    props.handleFieldEdited(false);
    return <APIError error={error} />;
  }
  return (
    <>
      {formSubmissionDone == true ? (
        <div className={styles.errorSuccess}>
          {validationError && (
            <div className={styles.errorHolder}>
              <div className={styles.esIcon}>
                <Icon name="exclamation-point-triangle" size="large"></Icon>
              </div>
              <div className={styles.esHead}>{errorData.errorType}</div>
              <div className={styles.esBody}>{errorData.errorMessage}</div>
            </div>
          )}
          {success && (
            <div className={styles.sucessHolder}>
              <div className={styles.esIcon}>
                <img
                  data-cslp={props.data?.$?.icon.url}
                  src={recognition_success_heading_icon}
                />

                {/* <Icon name='party-popper' size='large' variant='filled'></Icon> */}
              </div>
              <div className={styles.esHead}>{recognition_success_heading}</div>
              <div className={styles.esBody}>
                <InnerHtml description={formatedNames} />
              </div>
            </div>
          )}
          <div className={styles.btnHolder}>
            {!isManagerProfile && <Button
              label={recognize_more_label}
              className={`${styles.coButton} ${styles.cancelBtn}`}
              onClick={() => {
                setComment("");
                setFormSubmissionDone(false);
                setValidationError(false);
                setSuccess(false);
              }}
              variant="primary"
            />}
            <Button
              label={finish_label}
              variant="primary"
              onClick={() => {
                handleClose();
              }}
              className={`${styles.coButton} ${styles.submitBtn}`}
            />
          </div>
        </div>
      ) : (
        <div>
          {!props.data ? (
            <></>
          ) : (
            <div className={styles.mainContainer}>
              {isModalOpen && (
                <>
                  <ConfirmationPopup
                    isModalOpen={isModalOpen}
                    onContinue={setIsContinue}
                    fieldEdited={fieldEdited}
                    onClose={() => {
                      setIsModalOpen(false);

                      //setZindex(false);
                    }}
                  />
                </>
              )}

              <div className={styles.indvMainItem}>
                <div className={styles.des}>
                  <InnerHtml description={formData?.data?.formDescription} />
                </div>
              </div>

              <div className={styles.indvItem}>
                <div className={styles.allLabel}>
                  {formData?.data?.formContent?.recognizing_name?.label}
                  <span className={styles.coStarField}>*</span>
                </div>
              </div>
              {isManagerProfile ? <div className={styles.chip}><span>{fullName}</span></div> : <div className={styles.indvMainItem}>
                <div className={styles.nameSearchHolder}>
                  <MultiSelection
                    employeeSelected={setEmployees}
                    employeeNames={setEmployeeNames}
                  />
                </div>
              </div>}


              <div className={styles.indvItem}>
                <div className={styles.allLabel}>
                  {formData?.data?.formContent?.badge?.title}
                  <span className={styles.coStarField}>*</span>
                </div>
              </div>

              <div className={styles.indvMainItem}>
                <CustomSelectDropDownWithIcon
                  id="badge_type"
                  label="Select one"
                  // text="date"
                  //selected='0'
                  optionsList={getBadges()}
                  isType2={"TYPE_3"}
                  onChange={(index, obj) => {

                    setBadgeRef(obj.value);
                    setBadgeName(obj.name);
                  }}
                />
              </div>

              <div className={styles.indvItem}>
                <div className={styles.allLabel}>
                  {formData?.data?.formContent?.comment?.label}
                  <span className={styles.coStarField}>*</span>
                </div>
              </div>

              <div className={styles.indvMainItem}>
                <textarea
                  // id={content.attribute_id}
                  rows="5"
                  className={styles.textareaField}
                  placeholder={
                    formData?.data?.formContent?.comment?.place_holder_text
                  }
                  value={comment}
                  onChange={(e) => {

                    setComment(e.target.value);
                  }}
                ></textarea>
              </div>

              <div
                className={styles.formFieldSpacing}
                style={{ display: "flex", gap: "24px", justifyContent: "end" }}
              >
                <Button
                  label={cancelLabel}
                  className={`${styles.coButton} ${styles.cancelBtn}`}
                  onClick={() => {
                    if (fieldEdited == true) {
                      setIsModalOpen(true);
                    } else {
                      handleClose();
                    }
                    {
                      /*handleClose()*/
                    }
                  }}
                  variant="primary"
                />
                <Button
                  label={nextLabel}
                  disabled={!isAllFieldPopulated}
                  variant="primary"
                  onClick={() => {
                    dateToday = formatDate(new Date());
                    submitFormMutation({
                      variables: {
                        submitRecognitionInput: {
                          badge_Reference: badgeRef,
                          comment: comment,
                          feedbackGivenDate: dateToday,
                          toWorkerId: isManagerProfile ? employeeIdData : employees,
                        },
                      },
                    });
                  }}
                  className={`${styles.coButton} ${styles.submitBtn}`}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default SubmitRecognitionForm;