import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_EMPLOYEE_PROFILE } from "../../../features/api-wrapper/apiQueries";
import APIError from "../../../pages/ErrorHandling/APIError";
import { convertDateFormat } from "../../../utils/convertDateFormat";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import styles from "./ReporteeCard.module.css";
import { NOT_APPLICABLE } from "../../../Const";
import { Button } from "@mgmresorts/mgm-ui";
import FormModal from "../../../components/global/ui/FormModal";
import useFetchData from "../../../hooks/useFetchData";
import { useDispatch } from "react-redux";
import { authActions } from "../../../data/authentication-slice";
import { useSearchParams } from 'react-router-dom';

const ReporteeCard = (props) => {
  const { state } = useLocation();
  const { dictionaryLables, dictionaryWithIconLables,dictionary } = props.data;
  // const [badgesA, setBadgesA] = useState([]);
  const { data: badgeData } = useFetchData("", "recognition_badges");
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams(); 
  const [getEmployeeInfoCard, { loading, error, data }] = useLazyQuery(
    GET_EMPLOYEE_PROFILE,
    {
      client: apiMiddlewareClient,
      fetchPolicy: "no-cache",
    }
  );
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const cs_link = true;
  // console.log('prosdata', props.data)

  useEffect(() => {
    if(state)
    {
      getEmployeeInfoCard({
        variables: { memberId: state.memberID, teamId: state.teamID },
      });
    }
    else{
      let memberId = searchParams.get('memberID'); 
      let teamID = searchParams.get('teamID');
      getEmployeeInfoCard({
        variables: { memberId: memberId, teamId: teamID },
      });
    }
    
  }, []);
  useEffect(() => {
    badgeData &&
     
    dispatch(authActions.setBadgeData(badgeData));
  }, [badgeData]);
  console.log('aaa', props.data?.dictionaryWithIconLables);
  const fullNameData =
    data?.getEmployeeProfile?.data?.firstName +
    " " +
    data?.getEmployeeProfile?.data?.lastName;
  const emailData =
    data?.getEmployeeProfile?.data?.email === ""
      ? dictionaryLables.not_applicable
      : data?.getEmployeeProfile?.data?.email.toLowerCase();
  const employeeIdData =
    data?.getEmployeeProfile?.data?.employeeId === ""
      ? dictionaryLables.not_applicable
      : data?.getEmployeeProfile?.data?.employeeId;
  const serviceDateData =
    data?.getEmployeeProfile?.data?.continuousServiceDate === ""
      ? dictionaryLables.not_applicable
      : data?.getEmployeeProfile?.data?.continuousServiceDate;
  const phoneNumber =
    data?.getEmployeeProfile?.data?.workPhone === ""
      ? dictionaryLables.not_applicable
      : data?.getEmployeeProfile?.data?.workPhone;
  const dateOfBirth =
    data?.getEmployeeProfile?.data?.dateOfBirth === ""
      ? dictionaryLables.not_applicable
      : data?.getEmployeeProfile?.data?.dateOfBirth;
  const businessTitle = data?.getEmployeeProfile?.data?.businessTitle;
  const primaryCompanyName = data?.getEmployeeProfile?.data?.primaryCompanyName;
  const birthdayLabel = dictionaryWithIconLables?.birthday?.value;
  const serviceDateLabel =
    dictionaryWithIconLables?.continuous_service_date?.value;
  const emailLabel = dictionaryWithIconLables?.email?.value;
  const employeeIDLabel = dictionaryWithIconLables?.employee_id?.value;
  const phoneNoLabel = dictionaryWithIconLables?.phone_number?.value;
  const emailIcon = dictionaryWithIconLables?.email?.url;
  const employeeIdIcon = dictionaryWithIconLables?.employee_id?.url;
  const serviceDataIcon =
    dictionaryWithIconLables?.continuous_service_date?.url;
  const birthdayIcon = dictionaryWithIconLables?.birthday?.url;
  const phoneNoIcon = dictionaryWithIconLables?.phone_number?.url;
  const recognizeText = props.data?.dictionaryWithIconLables?.recognize_icon?.value;
  const recognizeIcon = props.data?.dictionaryWithIconLables?.recognize_icon?.url;
  const giveFeedbackButton =props.data?.linkDictionaryLables?.feedback_button?.link?.title;
  const giveFeedbackButtonURL = props.data?.linkDictionaryLables?.feedback_button?.link?.href;
  const recognizeFormHeading = dictionary?.filter((item)=>item.name=="recognize_form_heading")?dictionary.filter((item)=>item.name=="recognize_form_heading")[0]?.value?dictionary.filter((item)=>item.name=="recognize_form_heading")[0].value:dictionary.filter((item)=>item.name=="recognize_form_heading")[0]?.html_value:"";
  const employeesData =[{employeeID:employeeIdData,employeeName:fullNameData,location:primaryCompanyName,position:businessTitle}
]
  const openSubmitRecognitionForm = () => {
    setIsFormModalOpen(true);
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className={styles.personalInfoCard}>
        <APIError error={error} />
      </div>
    );

  return (
    <div className={styles.reporteeCardContainer}>
      <div className={styles.profileDetails}>
        <div className={styles.profileIcon}>
          <span>
            <button>
              {fullNameData
                ? fullNameData
                    .match(/(\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()
                : ""}
            </button>
          </span>
        </div>
        <div className={styles.info}>
          <div className={styles.infoname}>{fullNameData}</div>
          <div className={styles.personalsubtitle}>{businessTitle}</div>

          <div className={styles.personalResortName}>{primaryCompanyName}</div>
        </div>
      </div>
      <div className={styles.borderReportee} />
      <div className={styles.ReporteeDetailsFlex}>
        <img src={emailIcon} alt="" className={styles.reporteeImg} />
        <div className={styles.ReporteeEmailFlex}>
          <div className={styles.detailsSubTitle}>{emailLabel}</div>
          <a
            target="_top"
            href={`mailto:${emailData}`}
            className={styles.detailsValue}
          >
            {emailData}
          </a>
        </div>
      </div>
      <div className={styles.ReporteeDetailsFlex}>
        <img src={employeeIdIcon} alt="employee id icon" className={styles.reporteeImg} />
        <div className={styles.ReporteeEmailFlex}>
          <div className={styles.detailsSubTitle}>{employeeIDLabel}</div>
          <div className={styles.detailsValue}>
            {employeeIdData ? employeeIdData : ""}
          </div>
        </div>
      </div>
      <div className={styles.ReporteeDetailsFlexDuo}>
        <div className={styles.ReporteeDetailsFlexChild}>
          <img src={serviceDataIcon} alt="service date icon" className={styles.reporteeImg} />
          <div className={styles.ReporteeEmailFlex}>
            <div className={styles.detailsSubTitle}>{serviceDateLabel}</div>
            <div className={styles.detailsValue}>
              {serviceDateData != NOT_APPLICABLE
                ? convertDateFormat(serviceDateData)
                : dictionaryLables?.not_applicable}
            </div>
          </div>
        </div>
        <div className={styles.ReporteeDetailsFlexChild}>
          <img src={birthdayIcon} alt="birthday icon" className={styles.reporteeImg} />
          <div className={styles.ReporteeEmailFlex}>
            <div className={styles.detailsSubTitle}>{birthdayLabel}</div>
            <div className={styles.detailsValue}>{dateOfBirth}</div>
          </div>
        </div>
      </div>

      <div className={styles.ReporteeDetailsFlex}>
        <img src={phoneNoIcon} alt="phone icon" className={styles.reporteeImg} />
        <div className={styles.ReporteeEmailFlex}>
          <div className={styles.detailsSubTitle}>{phoneNoLabel}</div>
          <div className={styles.detailsValue}>
            {phoneNumber ? formatPhoneNumber(phoneNumber) : NOT_APPLICABLE}
          </div>
        </div>
      </div>
      <div className={styles.ReporteeDetailsFlex}>
        <img src={recognizeIcon} alt="recognize icon" className={styles.reporteeImg} />
        <div className={styles.ReporteeGiveFeedbackFlex}>
          <div className={styles.detailsSubTitle}>{recognizeText + " " + fullNameData}</div>
          {cs_link && (
            <Button
              variant="primary"
              className={styles.recogSomeoneButton}
              onClick={openSubmitRecognitionForm}
            >
              {giveFeedbackButton}
            </Button>
          )}
        </div>
      </div>
      {isFormModalOpen && cs_link && (
        <FormModal
          isFormModalOpen={isFormModalOpen}
          isManagerProfile={true}
          heading={recognizeFormHeading}
          fullName={fullNameData}
          employeeIdData={employeesData}
          // heading={cs_text?.recognize_someone_form_heading} // need from cs murali
          formURL={giveFeedbackButtonURL} // need from cs murali
          onClose={() => {
            setIsFormModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ReporteeCard;
