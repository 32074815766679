import { useEffect } from "react";
import { TextLink } from "@mgmresorts/mgm-ui";
import { useSearchParams } from "react-router-dom";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_LOA_DETAILS } from "../../../features/api-wrapper/apiQueries";
import APIError from "../../../pages/ErrorHandling/APIError";
import { useLazyQuery } from "@apollo/client";
import Loader from "../../../components/global/Loader";
import styles from "./DirectReporteeLOA.module.css";

const DirectReporteeLOA = (props) => {
  const [searchParams] = useSearchParams();

  const [getLOADetails, { loading, error, data }] = useLazyQuery(
    GET_LOA_DETAILS,
    {
      client: apiMiddlewareClient,
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (props?.memberId?.memberID) {
      getLOADetails({
        variables: {
          memberId: props.memberId.memberID,
        },
      });
    } else {
      let memberId = searchParams.get("memberID");
      getLOADetails({ variables: { memberId: memberId } });
    }
  }, []);

  //   const fmlaData = null;
  const isFMLACardHidden = data?.mmtgetLeaveOfAbsenceDetails?.data?.isFMLACardHidden;
  const viewAllText = props?.data?.linkDictionaryLables?.view_all?.link?.title;
  const viewAllHref = props?.data?.linkDictionaryLables?.view_all?.link?.href;
  const showingNum = props?.data?.dictionaryLables?.showing_of;
  const fromText = props?.data?.dictionaryLables?.from;
  const noLoaText = props?.data?.dictionaryLables?.no_loa_message;
  //const loaText = props?.data?.dictionaryLables?.tab2_title;
  const asOfText = props?.data?.dictionaryLables?.as_of;
  const totalHoursText = props?.data?.dictionaryLables?.total_hours;
  //const fmlaText = props?.data?.dictionaryLables?.fmla;
  const totalFMLAHours = data?.mmtgetLeaveOfAbsenceDetails?.data?.fmlaData?.balance;
  const asOfDateFMLA = data?.mmtgetLeaveOfAbsenceDetails?.data?.fmlaData?.asOfDate;
  const ApprovalPending = props?.data?.dictionaryLables?.approval_pending;

  const changeDateFormat = (date) => {
    const dateArray = date.split("-");
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  };

  const replaceShowing = (template, showing, total) => {
    return template
      .replace("{variable-showing}", showing)
      .replace("{variable-total}", total);
  };
  // console.log(
  //   "!!0101",
  //   data?.mmtgetLeaveOfAbsenceDetails?.data
  // );

  if (loading) return <Loader />;
  if (error) {
    return (
      <div className={styles.personalInfoCard}>
        <APIError error={error} />
      </div>
    );
  } else {
    return (
      <div className={styles.loaContainer}>
        <div className={styles.cardContainerShowmore}>
          <div className={styles.showMoreLabel}>
            <div dangerouslySetInnerHTML={{__html: replaceShowing(showingNum, data?.mmtgetLeaveOfAbsenceDetails?.data?.loaDetails?.length, data?.mmtgetLeaveOfAbsenceDetails?.data?.loaDetails?.length)}}></div>
            {/* {replaceShowing(showingNum, data?.mmtgetLeaveOfAbsenceDetails?.data?.loaDetails?.length, data?.mmtgetLeaveOfAbsenceDetails?.data?.loaDetails?.length)} */}
            {/* {`${globalProperties?.showing} ${"3 of 3"}`} */}
          </div>
          <TextLink className={styles.viewAll} target="_blank" href={viewAllHref}>
            {viewAllText}
          </TextLink>
        </div>
        {!isFMLACardHidden && data?.mmtgetLeaveOfAbsenceDetails?.data?.fmlaData && (
          <div className={styles.fmlaHeader}>
            <div className={styles.fmlaLeft}>
              {/*<div className={styles.fmla}>{fmlaText}</div>*/}
              <div className={styles.fmlaContent}>{asOfText} {changeDateFormat(asOfDateFMLA)}</div>
            </div>
            <div className={styles.fmlaHours}>
              <span className={styles.fmlaNumber}>{totalFMLAHours}</span>
              <span className={styles.fmlaText}>{totalHoursText}</span>
            </div>
          </div>
        )}
        <div className={styles.loaList}>
          {error ? (
            <p className={styles.errorMessage}>
              <APIError error={error} />
            </p>
          ) : data &&
            data?.mmtgetLeaveOfAbsenceDetails?.data?.loaDetails?.length > 0 ? (
            data?.mmtgetLeaveOfAbsenceDetails?.data?.loaDetails.map(
              (entry, index) => (
                <div key={index} className={styles.loaItem}>
                  {/*<div className={styles.loaLabel}>{loaText}</div>*/}
                  <div>
                    <p className={styles.loaType}>{entry.loaName?.split('>')[entry.loaName?.split('>')?.length-1]}</p>
                    {entry.isDatePeriodAvailable ? (
                      <p className={styles.loaDuration}>
                        <span>
                          {entry.quantity} {entry.units} {fromText}{" "}
                          {changeDateFormat(entry.startDate)} -{" "}
                          {changeDateFormat(entry.endDate)}
                        </span>
                      </p>
                    ) : (
                      <p className={styles.loaPending}>{ApprovalPending}</p>
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <div className={styles.loaItem}>
              {/*<div className={styles.loaLabel}>{loaText}</div>*/}
              <div className={styles.loaFallbackMessage}>
                {noLoaText}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default DirectReporteeLOA;
