import { gql } from "@apollo/client";

export const GET_SAVING_PLAN_DETAILS = gql`
  query Query($date: String!) {
    get401KDetails(effectiveDate: $date) {
      isUserEnrolled
      totalBalance
      coverage401K
      coverage401KRoth
      statusCode
      statusMessage
    }
  }
`;

export const FEEDBACK_RE_GI = gql`
  query Query {
    feedbackReceived {
      recognitionReceived {
        feedback
        feedbackFromName
        feedbackToName
        feedbackPersonLocation
        feedbackDate
        badgeName
        badgeID
      }
      recognitionMetrics {
        badgeID
        count
      }
    }
    feedbackGiven {
      feedback
      feedbackFromName
      feedbackToName
      feedbackPersonLocation
      feedbackDate
      badgeName
      badgeID
    }
  }
`;

export const GET_PAY_DETAILS = gql`
  query GetPayslip {
    payslip {
      takeHomePay
      payslipStartDate
      currencyCode
      payslipEndDate
      grossAmount
      location
      payGroupReference
      payslipPaymentDate
      timesheetHours
      earnings {
        amount
        category
        name
      }
      deductions {
        amount
        category
        name
      }
    }
  }
`;
export const SET_HIDE_PAY = gql`
  mutation UpdateHidePayStatus($status: Boolean!) {
    updateHidePayStatus(status: $status)
  }
`;
export const GET_HR_DETAILS = gql`
  query HrItCases {
    hrCases {
      noOfOpenCases
      noOfTasks
    }
  }
`;
export const GET_NOTIFICATION = gql`
  query NotificationQuery($fromDate: String!, $toDate: String!) {
    notifications(fromDate: $fromDate, toDate: $toDate) {
      message
      timeReceived
    }
  }
`;

export const GET_IT_DETAILS = gql`
  query HrItCases {
    itCases {
      noOfOpenCases
      noOfApprovals
    }
  }
`;

export const GET_ATTENDANCE_DETAILS = gql`
  query GetAttendanceDetails {
    attendanceHistory {
      date
      location
      quantity
      unit
    }
    attendanceLeaveBalance {
      allocationEndDate
      allocationStartDate
      locationDescription
      multiplePropertyIndicator
      positionDescription
      positionID
      remainingDays
      companyCode
      companyName
    }
  }
`;
export const GET_EligibleLOAPlans = gql`
  query EligibleLOAPlans {
    eligibleLOAPlans {
      planDisplayName
      subPlanDisplayName
      planReferenceId
      planReasons {
        reasonName
        reasonRefId
      }
    }
    positionReferences {
      positionId
      positionName
      primary
    }
  }
`;
export const GET_ONLY_PROFILE_DETAILS = gql`
  query getProfileDetails {
    profile {
      employeeId
      workerId
      fullName
      preferredName
      userLocation
      legalFirstName
      legalLastName
      primaryLocation
      primaryLocationLogo
      businessTitle
      preferredLanguage
      hidePayslip
      workdayDown
      newHire
      favourites {
        url
        title
        subTitle
        description
      }
      payRateGroup
      isFTOEligible
      isMultiplePropertyEmployee
    }
  }
`;

export const GET_PROFILE_DETAILS = gql`
  query getProfileDetails {
    personalInformation {
      fullName
      preferredName
      preferredFirstName
      preferredLastName
      personalEmail
      secondaryEmail
      homePhone
      addressLine1
      addressLine2
      postalCode
      workEmailAddresses
      city
      state
      preferredLanguage
      licencePlate {
        id
        state
        plateNo
      }
    }
    profile {
      employeeId
      workerId
      fullName
      preferredName
      userLocation
      legalFirstName
      legalLastName
      primaryLocation
      primaryLocationLogo
      businessTitle
      preferredLanguage
      hidePayslip
      workdayDown
      newHire
      favourites {
        url
        title
        subTitle
        description
      }
      payRateGroup
      isFTOEligible
      isMultiplePropertyEmployee
    }
    supervisorDetails {
      employeeId
      workerId
      name
      workEmail
      workPhoneNumber
      location
      businessTitle
    }
  }
`;
export const GET_TIMEOFFBALANCE_DETAILS = gql`
  query GetTimeOffBalanceDetails {
    timeOffLeaveBalance {
      positionID
      positionDescription
      timeOffPlanName
      timeOffBalance
      balancePeriodStartDate
      balancePeriodEndDate
      timeOffAccured
      locationId
      locationDescription
      multiplePropertyEmployee
      companyCode
      companyName
    }
    timesheetMultipleLocations {
      Company
      code
    }
  }
`;

export const GET_TIMEOFFREQUEST_DETAILS = gql`
  query GetTimeOffRequestDetails {
    timeOffRequests {
      timeOffRequestId
      locationId
      locationDescription
      timeOffRequestStatus
      totalUnits
      unitOfTime
      timeOffStartDate
      approvedDate
      timeOffName
    }
  }
`;
export const GET_TIMEOFFREQUEST_DETAILS_UPDATED = gql`
  query GetTimeOffRequestDetailsUpdated {
    updatedTimeOffRequests {
      approvedDate
      locationDescription
      locationId
      timeOffName
      timeoffCombined {
        quantity
        timeOffDate
        units
      }
      timeOffRequestId
      timeOffRequestStatus
      timeoffDates {
        quantity
        timeOffDate
        units
      }
    }
  }
`;

export const GET_PAST_SIX_MONTH_FTO = gql`
  query GetSixMonthFTO {
    PastSixMonthFTOCount {
      units
      typeOfUnits
    }
  }
`;
export const GET_EMPLOYEE_NAME = gql`
  query SearchByEmployeeName($searchString: String!) {
    searchByEmployeeName(searchString: $searchString) {
      employeeName
      position
      location
      employeeID
    }
  }
`;
export const GET_EMPLOYEE_DETAILS = gql`
  query employeeDetails($employeeId: Int!) {
    employeeDetails(employeeId: $employeeId) {
      data {
        employeeId
        name
        businessTitle
        location
        companyName
      }
      errorMessage
      status
    }
  }
`;

export const GET_FEATURED_PROPERTY_NEWS = gql`
  query Query {
    featuredPropertyNews {
      images {
        imageHeight
        imageURL
        imageWidth
      }
      imagesExist
      links
      linksExist
      newsBody
      newsContentType
      newsId
      newsPublishedDate
      newsSummary
      newsTitle
      videoExists
      videos {
        videoPreviewImageURL
        videoEmbedHTML
        videoWidth
        videoHeight
        videoUrl
        videoMP4Url
      }
      leoLink
    }
  }
`;

export const GET_PROPERTY_CALENDER = gql`
  query FilteredPropertyCalendar {
    filteredPropertyCalendar {
      leolink
      body
    }
  }
`;

export const GET_CLOCKEDHOURS = gql`
  query clockedHours {
    clockedHours {
      currentSystemDate
      employeeTimezone
      error
      isClockedIn
      lastPunchTime
      lastPunchType
      timeframeEndDate
      timeframeStartDate
    }
  }
`;
export const GET_WEEKLYUPDATES = gql`
  query weeklyUpdates {
    weeklyUpdates {
      images {
        imageURL
        imageWidth
        imageHeight
      }
      leoLink
      title
    }
  }
`;
export const GET_RELATED_NEWS = gql`
  query Query {
    relatedNews {
      newsId
      newsPublishedDate
      newsTitle
      newsSummary
      newsContentType
      newsBody
      linksExist
      links
      imagesExist
      images {
        imageURL
        imageWidth
        imageHeight
      }
      videoExists
      leoLink
      videos {
        videoPreviewImageURL
        videoEmbedHTML
        videoWidth
        videoHeight
        videoUrl
        videoMP4Url
      }
    }
  }
`;

// check if this query is duplicate
export const GET_MGM_NEWS = gql`
  query MgmNews($pageNumber: Int, $size: Int) {
    mgmNews(pageNumber: $pageNumber, size: $size) {
      newsId
      newsPublishedDate
      newsTitle
      newsSummary
      newsContentType
      newsBody
      linksExist
      links
      imagesExist
      images {
        imageURL
        imageWidth
        imageHeight
      }
      videoExists
      videos {
        videoPreviewImageURL
        videoEmbedHTML
        videoWidth
        videoHeight
        videoUrl
        videoMP4Url
      }
      leoLink
    }
  }
`;
export const updateLOADetails = gql`
  mutation RequestleaveOfAbsence($requestLoaInput: RequestLOAInput!) {
    requestleaveOfAbsence(requestLOAInput: $requestLoaInput) {
      statusCode
      statusMessage
      errorCode
      errorMessage
    }
  }
`;

export const ADD_FAVOURITES = gql`
  mutation Mutation(
    $pageUrl: String!
    $title: String
    $subTitle: String
    $description: String
  ) {
    addFavourite(
      pageUrl: $pageUrl
      title: $title
      subTitle: $subTitle
      description: $description
    ) {
      status
      data {
        title
        subTitle
        url
        description
      }
      errorMessage
    }
  }
`;

export const REMOVE_FAVOURITES = gql`
  mutation RemoveFavourite($pageUrl: String!) {
    removeFavourite(pageUrl: $pageUrl) {
      errorMessage
      status
    }
  }
`;

export const GET_PROPERTIES = gql`
  query EmployeeProperties {
    employeeProperties {
      id
      name
    }
  }
`;

export const GET_TIMESHEET_MULTIPLE_LOCATIONS = gql`
  query TimesheetMultipleLocations {
    timesheetMultipleLocations {
      Company
      code
    }
  }
`;

export const GET_PAYPERIODS_DROPDOWN = gql`
  query PayperiodsDropDown {
    payperiodsDropDown {
      companyCode
      companyName
      positionID
      payPeriods {
        payPeriodStartDate
        payPeriodEndDate
      }
    }
  }
`;
export const GET_JOBOPP_DETAILS = gql`
  query JobOpportunityData(
    $startDate: String
    $property: String
    $jobCategory: String
  ) {
    getJobPostingsList(
      startDate: $startDate
      property: $property
      jobCategory: $jobCategory
    ) {
      jobType
      jobExemptType
      jobInternalURL
      jobTitle
      jobPostingDate
      jobLocation
      jobId
      jobDescription
    }
    getDefaultJobCategory {
      name
      wid
      count
    }
  }
`;
export const GET_JOBOPP_FILTER = gql`
  query JobOppFilter(
    $getDynamicFilterCategoriesStartDate2: String
    $getDynamicFilterCategoriesProperty2: String
    $getDynamicFilterCategoriesJobCategory2: String
  ) {
    getDynamicFilterCategories(
      startDate: $getDynamicFilterCategoriesStartDate2
      property: $getDynamicFilterCategoriesProperty2
      jobCategory: $getDynamicFilterCategoriesJobCategory2
    ) {
      location {
        name
        wid
        count
      }
      jobCategory {
        name
        wid
        count
      }
      defaultJobCategory {
        name
        wid
        count
      }
    }
  }
`;

export const GET_TIMESHEET_DETAILS = gql`
  query Profile($startDate: String, $endDate: String, $locationId: String) {
    timeSheet(
      startDate: $startDate
      endDate: $endDate
      locationID: $locationId
    ) {
      payPeriodStartDate
      payPeriodEndDate
      isApproved
      payCodes {
        payCodeName
        payCodeDateTime
        payCodeAmountInSeconds
      }
      timesheetDateWise {
        timeSheetRow {
          timeIn
          timeOut
          isTimeInEdited
          isTimeOutEdited
          missedInPunch
          missedOutPunch
          location
          transferIndicator
          exception
          tipCompliance
          tipsBreakdown {
            TipName
            Amount
          }
        }
        timesheetDate
        hours
        isDateApproved
      }
    }
  }
`;

export const SET_PREFERRED_LANGUAGE = gql`
  mutation UpdateLanguagePreference($language: String!) {
    updateLanguagePreference(language: $language)
  }
`;

export const MY_TASKS_NOTIFICATION = gql`
  query MyTasks {
    myTasks {
      newItems
      tasks {
        taskMessage
        taskDescription
        timeReceived
        dueOn
        isRead
      }
    }
  }
`;

export const GET_MY_APPS = gql`
  query myApps($locale: String) {
    myApps(locale: $locale) {
      icon
      displayName
      description
      link
      source
      subHeading
    }
  }
`;

export const GET_USER_ENTERED_CONTEST = gql`
  query Query {
    hasUserEnteredContest {
      userContestParticipatedStatus
    }
  }
`;

export const SET_USER_ENTERED_CONTEST = gql`
  mutation ContestLaunchEvent($systemDate: String) {
    contestLaunchEvent(systemDate: $systemDate) {
      status
      userContestParticipatedStatus
      systemDate
      employeeId
      employeeName
      employeeBusinessTitle
      employeePrimaryLocation
    }
  }
`;

export const GET_MY_SCHEDULE = gql`
  query MySchedule {
    mySchedule {
      dayWiseSchedule {
        startTime
        endTime
        overnight
        startDate
        site
        role
        location
        notes
        leaves
      }
      onWaitlistDates
      dayTagDates
      isMessageAvailable
      isAckMessageAvailable
    }
  }
`;

export const GET_REQUEST_TIMEOFF = gql`
  query Query {
    eligibleTimeOffPlans {
      positionID
      timeOffPlans {
        planDisplayName
        planReferenceCode
        planUnitOfTime
      }
    }
    positionReferences {
      positionId
      positionName
      primary
    }
  }
`;

export const UPDATE_REQUEST_TIMEOFF = gql`
  mutation Mutation($requestTimeOffInput: RequestTimeOffInput!) {
    requestTimeOff(requestTimeOffInput: $requestTimeOffInput) {
      statusCode
      statusMessage
      errorCode
      errorMessage
    }
  }
`;

export const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncements($currentDate: String!) {
    getAnnouncements(currentDate: $currentDate) {
      ... on NewHireAnnouncement {
        milestone
      }
      ... on BirthdayAnnouncement {
        birthday
      }
      ... on AnniversaryAnnouncement {
        yearsCompleted
        anniversaryDate
      }
      ... on FeedbackAnnouncement {
        feedbackFrom
        feedbackDate
        badgeId
      }
    }
  }
`;

export const GET_BENEFITS = gql`
  query GetBenefits($effectiveDate: String!) {
    getBenefits(effectiveDate: $effectiveDate) {
      currentBenefits {
        benefitPlan {
          name
          newPlanIndicator
        }
        coverageBeginDate
        coverage
        deductionBeginDate
        Dependents
        currentlyEnrolled
        wellnessCredit
        employeeCost {
          amount
          rateChangeIndicator
        }
        employerCost
      }
      upcomingBenefits {
        benefitPlan {
          name
          newPlanIndicator
        }
        coverageBeginDate
        coverage
        deductionBeginDate
        Dependents
        currentlyEnrolled
        wellnessCredit
        employeeCost {
          amount
          rateChangeIndicator
        }
        employerCost
      }
      totalEmployeeCostCurrentBenefit
      totalEmployerCostCurrentBenefit
      totalEmployeeCostUpcomingBenefit
      totalEmployerCostUpcomingBenefit
      isOpenEnrollmentStarted
      isOpenEnrollmentCompleted
      IsEnrollmentPlanSubmitted
      enrollmentStartDate
      enrollmentEndDate
    }
  }
`;

export const GET_EDR_MENU = gql`
  query GetEDRMenu($currentDate: String!) {
    getEDRMenu(currentDate: $currentDate) {
      file
      propertyName
      startDate
      endDate
    }
  }
`;

export const UPLOAD_EDR_MENU = gql`
  mutation UploadEDR($updateEdrInput: UpdateEDRInput!, $file: Upload!) {
    uploadEDR(updateEDRInput: $updateEdrInput, file: $file) {
      statusCode
      statusMessage
      errorCode
      errorType
      errorMessage
    }
  }
`;

export const GET_VOLUNTEERING_NEWS_EVENTS = gql`
  query GetVolunteeringNewEvents(
    $volnteeringEventInput: VolnteeringEventInput
  ) {
    getVolunteeringNewEvents(volnteeringEventInput: $volnteeringEventInput) {
      page
      pageSize
      totalCount
      pageCount
      events {
        eventId
        title
        startDateTimeInUTC
        endDateTimeInUTC
        startDateTimeLocal
        endDateTimeLocal
        imageUrl
        deepLink
        location {
          address1
          address2
          city
          state
          zip
          countryCode
        }
      }
      employeeLocation
    }
  }
`;

export const GET_EMPLOYEE_VOLUNTEERING_MATRIX = gql`
  query GetEmployeeVolunteerMetrics {
    getEmployeeVolunteerMetrics {
      donations
      hours
      charitiesReached
      status
    }
  }
`;

export const GET_MY_UPCOMING_EVENTS = gql`
  query GetUpcomingEvents($locationCityState: String) {
    getUpcomingEvents(locationCityState: $locationCityState) {
      eventId
      title
      startDateTimeInUTC
      endDateTimeInUTC
      startDateTimeLocal
      endDateTimeLocal
      imageUrl
      deepLink
      location {
        address1
        address2
        city
        state
        zip
        countryCode
      }
    }
  }
`;

export const USER_HAS_READ_MESSAGE = gql`
  mutation Mutation($messageId: String!) {
    userHasReadMessage(messageID: $messageId) {
      status
      statusMessage
    }
  }
`;

export const GET_OPEN_LOA_REQUEST_STATUS = gql`
  query GetOpenLOARequestStatus {
    getOpenLOARequestStatus {
      totalCount
      caseStatusList {
        caseId
        requestDate
        category
        subCategory
        status
        statusColourIndicator
        link
      }
      isFMLACardHidden
      fmlaData {
        statusCode
        statusMessage
        data {
          asOfDate
          balance
        }
      }
    }
  }
`;


export const GET_EMPLOYEE_PROFILE = gql`
  query GetEmployeeProfile($memberId: String!, $teamId: String!) {
    getEmployeeProfile(memberId: $memberId, teamId: $teamId) {
      data {
        employeeId
        firstName
        lastName
        businessTitle
        primaryCompanyName
        email
        workPhone
        dateOfBirth
        continuousServiceDate
      }
      statusMessage
      statusCode
    }
  }
`;

export const GET_DIRECT_REPORTS = gql`
  query GetDirectReports($memberId: String) {
    getDirectReports(memberId: $memberId) {
      statusCode
      statusMessage
      data {
        managerName
        totalCount
        teams {
          id
          name
          count
          members {
            id
            name
            businessTitle
            primaryCompanyName
            indirectReportCount
          }
        }
      }
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers($teamId: String!) {
    getTeamMembers(teamId: $teamId) {
      statusCode
      statusMessage
      data {
        id
        name
        businessTitle
        primaryCompanyName
        indirectReportCount
      }
    }
  }
`;

export const GET_MANAGER_TASK_TODO = gql`
  query GetManagerTaskTodoModalData {
  mmtTasksToDo {
    statusCode
    statusMessage
    totalCount
    todos {
      title
      dateInitiated
      memberName
      message
    }
  }
}
`;

export const GET_MANAGER_TASK_TODO_CELEBRATIONS = gql`
 query GetManagerTaskTodoCelebrations {
  mmtGetTeamCelebrations {
    statusCode
    statusMessage
    data {
      type
      employee
      date
      year
    }
  }
}
`;

// not in use at the moment, but can be used in the future [manager dashboard direct reportee]
// export const GET_PAST_SIX_MONTHS_TIME_OFF_COUNT = gql`
// query MmtGetPastSixMonthsTimeOffCount($memberId: String!) {
//   mmtGetPastSixMonthsTimeOffCount(memberId: $memberId) {
//     statusCode
//     statusMessage
//     data {
//       units
//       typeOfUnits
//     }
//   }
// }
// `;


// export const GET_TIME_OFF_DETAILS = gql`
// query MmtGetTimeOffDetails($memberId: String!) {
//   mmtGetTimeOffDetails(memberId: $memberId) {
//     statusCode
//     statusMessage
//     data {
//       timeOffRequestId
//       timeOffName
//       timeoffDates {
//         timeOffDate
//         quantity
//         units
//       }
//       timeoffCombined {
//         timeOffDate
//         quantity
//         units
//       }
//       timeOffRequestStatus
//       approvedDate
//     }
//   }
// }
// `;

export const GET_TIME_OFF_BALANCES = gql`
query mmtGetTimeOffBalanceAndPastSixMonths($memberId: String!) {
  mmtGetTimeOffBalanceAndPastSixMonths(memberId: $memberId) {
    statusCode
    statusMessage
    timeOffBalanceData {
      timeOffPlanName
      timeOffBalance
    }
    isPastSixMonthsDataHidden
    PastSixMonthsData {
      statusCode
      statusMessage
      data {
        units
        typeOfUnits
      }
    }
  }
}
`;

export const GET_LOA_DETAILS = gql`
query MmtgetLeaveOfAbsenceDetails($memberId: String!) {
  mmtgetLeaveOfAbsenceDetails(memberId: $memberId) {
    statusCode
    statusMessage
    data {
      loaDetails {
        loaName
        quantity
        units
        startDate
        endDate
        isDatePeriodAvailable
      }
      isFMLACardHidden
      fmlaData {
        asOfDate
        balance
      }
    }
  }
}
  `;



