import { useEffect, useState } from "react";
import MatrixWrapper from "./MatrixWrapper";
import styles from "./VolunteeringMatrix.module.css";
import ErrorHanding from "../../ErrorHandling/ErrorHanding";
const EMPTY_STRING = "";

const VolunteeringMatrix = (props) => {
  const data = props?.data?.data;
  const [cardData, setCardData] = useState();
  const [cardTitle, setCardTitle] = useState();
  const error = props?.data?.error||props?.data?.graphQLErrors?props?.data:null;
  
  useEffect(() => {
    if (data) {
      if (data.dictionary_with_icon && data.dictionary_with_icon.length > 0) {
        const groupedData = data.dictionary_with_icon.reduce((acc, value) => {
          if (!acc[value.name]) {
            acc[value.name] = "";
          }
          acc[value.name] = value;
          return acc;
        }, {});
        setCardData(groupedData);
      }
      if (data.dictionary && data.dictionary.length > 0) {
        const dictionaryTitles = data.dictionary.reduce((acc, value) => {
          if (!acc[value.name]) {
            acc[value.name] = "";
          }
          acc[value.name] = value.value || value.html_value;
          return acc;
        }, {});
        setCardTitle(dictionaryTitles);
      }
    }
  }, [data]);

  return (
    <>
      {props?.data?.getEmployeeVolunteerMetrics?.status != "404" && (
        error?<><ErrorHanding error={error} /></>:<div className={`${styles.matrixHolder}`}>
          <div className={styles.card}>
            <div className={styles.iconH}>
              <img
                src={
                  cardData?.pay_icon
                    ? cardData?.pay_icon?.icon?.url
                    : EMPTY_STRING
                }
              />
            </div>
            <div className={styles.cardData}>
              $
              {Number(
                props?.data?.getEmployeeVolunteerMetrics?.donations
              ).toFixed(2)}
            </div>
            <div className={styles.cardDes}>{cardTitle?.widget_1_title}</div>
          </div>
          <div className={styles.card}>
            <div className={styles.iconH}>
              <img
                src={
                  cardData?.arrow_clock_icon
                    ? cardData.arrow_clock_icon?.icon?.url
                    : EMPTY_STRING
                }
              />
            </div>
            <div className={styles.cardData}>
              {props?.data?.getEmployeeVolunteerMetrics?.hours}
            </div>
            <div className={styles.cardDes}>{cardTitle?.widget_2_title}</div>
          </div>
          {/*<div className={styles.card}>
            <div className={styles.iconH}>
              <img
                src={
                  cardData?.heart_icon
                    ? cardData.heart_icon?.icon?.url
                    : EMPTY_STRING
                }
              />
            </div>
            <div className={styles.cardData}>
              {props?.data?.getEmployeeVolunteerMetrics?.charitiesReached}
            </div>
            <div className={styles.cardDes}>{cardTitle?.widget_3_title}</div>
              </div>*/}
        </div>
      )}
    </>
  );
};

export default MatrixWrapper(VolunteeringMatrix);
