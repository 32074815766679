import { LegacyCarousel } from "@mgmresorts/mgm-ui";
import { useEffect, useState,useRef } from "react";
import ConfirmationPopup from "../../components/global/ConfirmPrompt";
import APIError from "../../pages/ErrorHandling/APIError";
import LinkedInFeedCarousalItems from "./LinkedInFeedCarousalItems";
import style from "./LinkedinFeed.module.css";
import Loader from "../../components/global/Loader";
const LinkedinFeedChild = (props) => {
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);
  const [dataTrue, setDataTrue] = useState(false);
  const [linkedInFeedNodeList, setLinkedInFeedNodeList] = useState([]);
  const [cards, setCards] = useState([]);
  const linkedInCount = document.querySelectorAll(
    ".swiper-wrapper.swe_posts.swe_show > div"
  )?.length;
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  function windowOpen(url, name) {
    if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }
    return window.open(url, name);
  }

  useEffect(() => {
    if (isContinue) {
      setIsConfirmPopupOpen(false);
      setIsContinue(false);
      windowOpen(
        props?.data?.data?.links_dictionary?.filter(
          (item) => item.name == "view_profile"
        )[0]?.link?.href,
        "_blank"
      );
    }
  }, [isContinue]);
  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    if (deviceSize < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);

  const linkArea = (
    <a
      className={style.modalRedirect}
      onClick={() => setIsConfirmPopupOpen(true)}
    >
      {
        props?.data?.data?.links_dictionary?.filter(
          (item) => item?.name == "view_profile"
        )[0]?.link?.title
      }
    </a>
  );

  const header = !isMobile ? (
    <div>
      <div className={style.linkedLine}>
        <span className={style.titleLinkedIn}>
          {props?.data?.data?.dictionaryLables?.title}
        </span>
        <img
          className={style.linkedInIcon}
          src={
            props?.data?.data?.dictionary_with_icon?.filter(
              (item) => item?.name == "LinkedIn_icon_title"
            )
              ? props.data.data.dictionary_with_icon.filter(
                  (item) => item?.name == "LinkedIn_icon_title"
                )[0]?.url
              : ""
          }
        ></img>
      </div>
      <div className={style.linkedLine}>
        <div
          style={{
            background: `url("${
              props?.data?.data?.dictionary_with_icon?.filter(
                (item) => item?.name == "ceo_icon"
              )
                ? props.data.data.dictionary_with_icon.filter(
                    (item) => item?.name == "ceo_icon"
                  )[0]?.url
                : ""
            }")lightgray 50% / cover no-repeat`,
            borderRadius: "63.5px",
            width: "47px",
            height: "49px",
            flexShrink: "0",
          }}
        ></div>
        <p className={style.linkedInSubText}>
          <b>{props?.data?.data?.dictionaryLables?.ceo_name}</b>{" "}
          {props?.data?.data?.dictionaryLables?.ceo_designation}
        </p>
      </div>
    </div>
  ) : (
    <div>
      <div className={style.linkedLineMob}>
        <span className={style.titleLinkedIn}>
        <img
          className={style.linkedInIcon}
          src={
            props?.data?.data?.dictionary_with_icon?.filter(
              (item) => item?.name == "LinkedIn_icon_title"
            )
              ? props.data.data.dictionary_with_icon.filter(
                  (item) => item?.name == "LinkedIn_icon_title"
                )[0]?.url
              : ""
          }
        ></img>
        {" "}
          <span>{props?.data?.data?.dictionaryLables?.title}</span>
          
        </span>
       
      </div>
      <div className={style.linkedLine}>
        <div
          style={{
            background: `url("${
              props?.data?.data?.dictionary_with_icon?.filter(
                (item) => item?.name == "ceo_icon"
              )
                ? props.data.data.dictionary_with_icon.filter(
                    (item) => item?.name == "ceo_icon"
                  )[0]?.url
                : ""
            }")lightgray 50% / cover no-repeat`,
            borderRadius: "63.5px",
            width: "47px",
            height: "49px",
            flexShrink: "0",
          }}
        ></div>
        <p className={style.linkedInSubText}>
          <b>{props?.data?.data?.dictionaryLables?.ceo_name}</b>{" "}
          {props?.data?.data?.dictionaryLables?.ceo_designation}
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    if (
      document.querySelectorAll(".swiper-wrapper.swe_posts.swe_show > div")
        ?.length
    ) {
      setLinkedInFeedNodeList(
        document.querySelectorAll(".swiper-wrapper.swe_posts.swe_show > div")
      );
      return;
    } else {
      setDataTrue(!dataTrue);
    }
  }, [dataTrue]);

  useEffect(() => {
    if (linkedInFeedNodeList?.length) {
      let data = [...linkedInFeedNodeList];
      const cardData = data.map((item) => {
        const hoverText = item?.querySelectorAll(
          ".swe_post_text_container > span"
        )
          ? item.querySelectorAll(".swe_post_text_container > span")[0]
              ?.innerHTML
          : " " || " ";
        const backgroundImage =
          item?.querySelector("div.swe_post_image.swe_post_image_cover")?.style
            ?.backgroundImage || "";
        const linkedinRedirect =
          item?.querySelector("div.swe_post_meta_container")?.attributes[
            "data-permalink"
          ]?.nodeValue || "";
        const postDate =
          item?.querySelector("span.swe_post_date")?.innerText || "";
        return [hoverText, backgroundImage, linkedinRedirect, postDate];
      });
      setCards(cardData);
    }
  }, [linkedInFeedNodeList]);
  const nextButtonRef = useRef(null);
  const [firstClickTriggered, setFirstClickTriggered] = useState(false); 
  useEffect(() => {
    const nextButton = document.querySelector('[aria-label="forward"]');
    if (nextButton) {
      nextButtonRef.current = nextButton;
      nextButton.disabled = false;
      if (!firstClickTriggered && nextButtonRef.current) {
        // nextButtonRef.current.click(); // commented out due to it impacting Trending Topics
        setFirstClickTriggered(true);
      }
    }
  }, [cards]);
  return (
    <div>
      {!isMobile &&
        (linkedInCount > 0 ? (
          <LegacyCarousel
            header={header}
            onScroll={function noRefCheck() {}}
            spacing={24}
            step={"item"}
            swipeAmount={50}
            disabledOverflow={true}
            actions={linkArea}
          >
            {cards &&
              cards?.length > 0 &&
              cards.map((item, i) => {
                return (
                  <LinkedInFeedCarousalItems
                    key={i}
                    item={item}
                    cards={cards}
                    data={props?.data}
                  />
                );
              })}
          </LegacyCarousel>
        ) : linkedInCount==0?(
          <Loader/>
        ):<APIError error={"Linkedin api is down"} />)}
      {isMobile &&
        (linkedInCount > 0 ? (
          <LegacyCarousel
            mobile
            header={header}
            onScroll={function noRefCheck() {}}
            spacing={16}
            selected={0}
            step={"item"}
            hiddenArrows
            swipeEnabled
            swipeAmount={80}
            disabledOverflow={true}
            actions={linkArea}
          >
            {cards &&
              cards?.length > 0 &&
              cards.map((item, i) => {
                return (
                  <LinkedInFeedCarousalItems
                    key={i}
                    item={item}
                    cards={cards}
                    data={props?.data}
                  />
                );
              })}
          </LegacyCarousel>
        ) : linkedInCount==0?(
          <Loader/>
        ):<APIError error={"Linkedin api is down"} />)}
      {isConfirmPopupOpen && (
        <ConfirmationPopup
          isConfirmPopupOpen={isConfirmPopupOpen}
          onContinue={setIsContinue}
          onClose={() => {
            setIsConfirmPopupOpen(false);
          }}
        />
      )}
    </div>
  );
};
export default LinkedinFeedChild;
