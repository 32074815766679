import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { pageViewTracking } from "../appInsights";
import EDRLandingPage from "../components/dynamic/EDRLandingPage";
import { navigationActions } from "../data/navigation-slice";
import WithFetchNavigation from "../hoc/WithFetchNavigation";
import GetComponent from "../hooks/GetComponent";
import Dashboard from "../pages/Dashboard/Dashboard";
import LayoutOne from "../pages/DynamicPage/LayoutOne";
import HourlyClockedOut from "../pages/ErrorHandling/HourlyClockedOut";
import PageNotFound from "../pages/ErrorHandling/PageNotFound";
import Login from "../pages/Login/Login";
import SupportLogin from "../pages/Login/SupportLogin";
import SwitchUser from "../pages/Login/SwitchUser";
import SearchResults from "../pages/Search/SearchResults";
import AgentSearchlanding from "../pages/agentsearch/AgentSearch";
import EmployeeId from "../pages/agentsearch/EmployeeId";
import InitSearch from "../pages/agentsearch/InitSearch";
import { getLocalStoreValue, setLocalStoreValue } from "../utils/localStore";
import PrivateRoutes from "./PrivateRoutes";
//import Profile from "../components/global/Profile";
import ProfileWrapper from "../components/global/ProfileWrapper";

const { api } = window;

const Routers = (props) => {
  const [dataYouCopied, setdataYouCopied] = useState("");
  const [userRoles, setUserRoles] = useState();
  const [prevPageTitle, setPrevPageTitle] = useState();
  const gqlData = useSelector((state) => state.gqlData);
  const profile = gqlData?.profile;
  const payRateGroup = gqlData?.profile?.payRateGroup;
  let profileInfo;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const csGlobalData = useSelector((state) => state.globalNotification);

  useEffect(() => {
    dispatch(
      navigationActions.setRawResponse({
        csData: props.csData,
        csViewAll: props.csViewAll,
      })
    );
  }, [props.csData]);

  const pageTitle = useSelector((state) => state.dom.pageTitle);
  const token = getLocalStoreValue("token");
  let location = useLocation();

  useEffect(() => {
    if (location.pathname != "/login") {
      if (profile && userRoles && pageTitle) {
        profileInfo = {
          userid: profile.employeeId,
          employeeType: payRateGroup,
          businessLocation: profile.primaryLocation,
          role: profile.businessTitle,
          name: pageTitle,
          employeeRole: userRoles,
        };
        setPrevPageTitle(pageTitle);
        if (prevPageTitle !== pageTitle) {
          pageViewTracking(pageTitle, location.pathname, profileInfo);
        }
      }
      setLocalStoreValue("cpath", location.pathname);
      if(location.pathname != "/search")
      {
        setLocalStoreValue("searchBackPath", location.pathname);
      }
    }
  }, [location, profile, pageTitle, userRoles]);

  useEffect(() => {
    if (token) {
      const user = jwtDecode(token);
      setUserRoles(user.roles?.join(","));
    }
  }, [token]);

  useEffect(() => {
    try {
      // ipcRenderer.on becomes api.recieve
      api.recieve("electron-renderer", (args) => {
        //handle on download-progress event
        console.log(args, ">>>>> args ");
      });
      api.send("set-title", "hellow from renderer");
    } catch (error) {
      //console.log(error);
    }
  }, []);
  try {
    api.recieve("copiedMsg", (args) => {
      //handle on download-progress event
      //console.log(args[0], ">>>>> args ");
      setdataYouCopied(args[0]);
      //console.log('dataYouCopied >>>', dataYouCopied);
    });
  } catch (error) {
    //console.log(error);
  }
  useEffect(() => {
    // if (location.pathname != "/login") {
    // if (profile) {
    //   profileInfo = {
    //     userid: profile.employeeId,
    //     employeeType: payRateGroup,
    //     businessLocation: profile.primaryLocation,
    //     role: profile.businessTitle,
    //     name: pageTitle,
    //     employeeRole: userRoles,
    //   };
    //   // appInsights.trackPageView("login","/login",profileInfo);
    //   pageViewTracking(pageTitle, location.pathname, profileInfo);
    //   console.log("profileinfooo", profileInfo);
    // }
    // setLocalStoreValue("cpath", location.pathname);
    // }
    if (location.pathname == "/news-culture/leadership-messages") {
      // appInsights.trackPageView("leadershipmessages","/news-culture/leadership-messages",profileInfo);
      props.leadershipMsg(true);
    } else {
      props.leadershipMsg(false);
    }
    if (location.pathname == "/hourlyclockedout") {
      if (getLocalStoreValue("clockout") != "true") {
        // appInsights.trackPageView("HourlyClockedout","/hourlyclockedout",profileInfo);
        setLocalStoreValue("clockout", searchParams.get("clockout"));
        setLocalStoreValue("path", searchParams.get("path"));
      }

      try {
        props.clockedout(true);
      } catch (e) {
        //console.log(e);
      }
    } else {
      try {
        props.clockedout(false);
      } catch (e) {
        //console.log(e);
      }
    }
    if (location.pathname == "/edr-menu/uploadEDR") {
      setLocalStoreValue("uploadedr", searchParams.get("isedrupload"));
      setLocalStoreValue("path", searchParams.get("path"));
    }
    if (location.pathname == "/switchuser") {
      props.SwitchUserUI(true);
    } else {
      props.SwitchUserUI(false);
    }
    console.log('location.pathname $$$',location.pathname);
    if (location.pathname == "/search" || location.pathname == "/search/") {
      try {
        props.search(true);
      } catch (e) {
        console.log("error occured");
      }
    } else {
      try {
        props.search(false);
      } catch (e) {
        console.log("error occured");
      }
    }
    if (
      location.pathname == "/agentsearch" ||
      location.pathname == "/employeeId" ||
      location.pathname == "/agentsearchlanding"
    ) {
      try {
        //appInsights.trackPageView("Search",location.pathname,profileInfo);
        props.agentsearch(true);
      } catch (e) {
        //console.log("error occured");
      }
      if (
        location.pathname == "/searchresults" ||
        location.pathname == "/agentsearch" ||
        location.pathname == "/employeeId" ||
        location.pathname == "/agentsearchlanding"
      ) {
        try {
          // appInsights.trackPageView("SearchResultsPage",location.pathname,profileInfo);
          props.SearchResultPage(true);
        } catch (e) {
          //console.log("error occured");
        }
      }
    } else {
      try {
        props.SearchResultPage(false);
        props.agentsearch(false);
      } catch (e) {
        //console.log("error occured");
      }
    }
  }, [location]);

  const setElectronBgColor = (color) => {
    //console.log("color:::", color);
    try {
      api.send("setBgColor", color);
    } catch (e) {
      //console.log("error occured");
    }
  };

  const doResize = () => {
    try {
      api.send("resizeWindow", "resizeWindow");
    } catch (e) {
      //console.log("error occured");
    }
  };
  
  return (
    <>
      <Routes>
        <Route index path="/login" element={<Login />} />
        <Route path="/supportlogin" element={<SupportLogin />} />
        <Route path="/profile" element={<ProfileWrapper />} />

        <Route index path="/switchuser" element={<SwitchUser />} />
        <Route
          path="/searchresults"
          element={<SearchResults SearchResultPage={props.SearchResultPage} />}
        />
        <Route
          path="/agentsearch"
          element={<InitSearch electronBgColor={setElectronBgColor} />}
        />
        <Route
          path="/employeeId"
          element={
            <EmployeeId
              id={dataYouCopied}
              electronBgColor={setElectronBgColor}
              resize={doResize}
            />
          }
        />
        <Route
          path="/agentsearchlanding"
          element={<AgentSearchlanding></AgentSearchlanding>}
        ></Route>
        <Route
          path="/search"
          element={
            <AgentSearchlanding
              page="search"
              url="/search-page"
              pageType="page_search"
            ></AgentSearchlanding>
          }
        ></Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/hourlyclockedout" element={<HourlyClockedOut />} />
          <Route path="/edr-menu-upload" element={<EDRLandingPage />} />
          <Route path="/dashboarddummy" element={<Dashboard />} />
          <>
            {props.csData.map((route, index) => (
              <React.Fragment key={index}>
                {index === 0 && (
                  <Route
                    path="/"
                    element={
                      <GetComponent
                        name={route.AbsoluteUrl}
                        pageType={route.pageType}
                        entryId={route.PageUid}
                      />
                    }
                  />
                )}
                {route.Children == undefined ? (
                  <>
                    {
                      /* !(
                      (route.denySalaryWorker == "true" &&
                        payRateGroup == "Salary") ||
                      (route.denyHourlyWorker == "true" &&
                        payRateGroup == "Hourly")
                    ) && */ <Route
                        key={route.AbsoluteUrl}
                        path={route.AbsoluteUrl}
                        element={
                          <GetComponent
                            name={route.AbsoluteUrl}
                            pageType={route.pageType}
                            entryId={route.PageUid}
                          />
                        }
                      />
                    }
                  </>
                ) : (
                  <>
                    {
                      /* !(
                      (route.denySalaryWorker == "true" &&
                        payRateGroup == "Salary") ||
                      (route.denyHourlyWorker == "true" &&
                        payRateGroup == "Hourly")
                    ) && */
                      route.Children != undefined && (
                        <React.Fragment>
                          <Route
                            key={route.AbsoluteUrl}
                            path={route.AbsoluteUrl}
                            element={<PageNotFound />}
                          />
                          <Route
                            key={route.AbsoluteUrl}
                            path={route.AbsoluteUrl}
                          >
                            {route.Children.map((subroute, index) => (
                              <React.Fragment key={index}>
                                {subroute.Children === undefined ? (
                                  <>
                                    {
                                      /*payRateGroup &&*/
                                      /* props.csViewAll ||
                                        isElectron() ||
                                        !(
                                          (subroute.denySalaryWorker ==
                                            "true" &&
                                            payRateGroup == "Salary") ||
                                          (subroute.denyHourlyWorker ==
                                            "true" &&
                                            payRateGroup == "Hourly")
                                        )) && (*/
                                      <Route
                                        key={subroute.AbsoluteUrl}
                                        path={
                                          subroute.AbsoluteUrl.split("/")[2]
                                        }
                                        element={
                                          <GetComponent
                                            name={subroute.AbsoluteUrl}
                                            pageType={subroute.pageType}
                                            entryId={subroute.PageUid}
                                          />
                                        }
                                      />
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      /*payRateGroup &&*/
                                      /* props.csViewAll ||
                                        isElectron() ||
                                        !(
                                          (subroute.denySalaryWorker ==
                                            "true" &&
                                            payRateGroup == "Salary") ||
                                          (subroute.denyHourlyWorker ==
                                            "true" &&
                                            payRateGroup == "Hourly")
                                        )) && (*/
                                      <React.Fragment key={index}>
                                        {subroute.showChild === "false" && (
                                          <Route
                                            key={subroute.AbsoluteUrl}
                                            path={
                                              subroute.AbsoluteUrl.split("/")[2]
                                            }
                                            element={
                                              <GetComponent
                                                name={subroute.AbsoluteUrl}
                                                pageType={subroute.pageType}
                                                entryId={subroute.PageUid}
                                              />
                                            }
                                          />
                                        )}
                                        {subroute.showPage === "true" && (
                                          <Route
                                            key={subroute.AbsoluteUrl}
                                            path={
                                              subroute.AbsoluteUrl.split("/")[2]
                                            }
                                            element={
                                              <GetComponent
                                                name={subroute.AbsoluteUrl}
                                                pageType={subroute.pageType}
                                                entryId={subroute.PageUid}
                                              />
                                            }
                                          />
                                        )}
                                        <Route
                                          key={subroute.AbsoluteUrl}
                                          path={
                                            subroute.AbsoluteUrl.split("/")[2]
                                          }
                                        >
                                          {subroute.Children.map(
                                            (subrouteL2) => (
                                              <React.Fragment
                                                key={subrouteL2.AbsoluteUrl}
                                              >
                                                {
                                                  /* subrouteL2.denySalaryWorker !=
                                                    "false" &&*/ <Route
                                                    key={subrouteL2.AbsoluteUrl}
                                                    path={
                                                      subrouteL2.AbsoluteUrl.split(
                                                        "/"
                                                      )[3]
                                                    }
                                                    element={
                                                      <GetComponent
                                                        name={
                                                          subrouteL2.AbsoluteUrl
                                                        }
                                                        pageType={
                                                          subrouteL2.pageType
                                                        }
                                                        entryId={
                                                          subrouteL2.PageUid
                                                        }
                                                      />
                                                    }
                                                  />
                                                }
                                              </React.Fragment>
                                            )
                                          )}
                                        </Route>
                                      </React.Fragment>
                                    }
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                          </Route>
                        </React.Fragment>
                      )
                    }
                  </>
                )}
              </React.Fragment>
            ))}
          </>
          <Route path="/searchresults" element={<SearchResults />} />
          <Route path="/dynamic/:id" element={<LayoutOne />} />
          <Route
            path="/article"
            element={
              <GetComponent
                pageType="page_article"
                name={`/article?id=${searchParams.get("id")}`}
              />
            }
          />
          <Route
            path={
              csGlobalData.globalProperties
                ?.manage_my_team_employee_details_pageurl?.url
            }
            element={
              <GetComponent
                name={
                  csGlobalData.globalProperties
                    ?.manage_my_team_employee_details_pageurl?.url
                }
                pageType="page_detail"
              />
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default WithFetchNavigation(Routers);
