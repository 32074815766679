import { useState } from "react";
import styles from "./GenerateLetter.module.css";
import { Button } from "@mgmresorts/mgm-ui";
import axios from "axios";
import { getLocalStoreValue } from "../../utils/localStore";
import APIError from "../ErrorHandling/APIError";
import {  CenterModal } from "@mgmresorts/mgm-ui";

const GenerateLetter = (props) => {
  
  const { linkDictionaryLables, dictionaryLables } = props.data;
  const [error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleDownload = async () => {
    const fileUrl = linkDictionaryLables?.button?.link?.href;

    try {
      const token = getLocalStoreValue("token");
      const apigeeToken = getLocalStoreValue("apigee_token");
      const axiosBaseURL = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
      });
      // Pass headers and fetch the file
      const response = await axiosBaseURL.get(fileUrl, {
        headers: {
          Authorization: `Bearer ${apigeeToken}`,
          jwt: token,
        },
        responseType: "arraybuffer", // Ensures the file is fetched as a binary stream
      });
      // Create a Blob from the binary data if required
      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a temporary link to download the file
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = dictionaryLables?.eletter_file_name
        ? dictionaryLables?.eletter_file_name
        : "file"; // You can dynamically fetch the file name if needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // Clean up the URL after download
    } catch (error) {
      
      error.compName = 'letter';
      error.letter_not_available_text = dictionaryLables?.letter_not_available_text;
      document.body.style.overflow = "hidden";
      setIsModalOpen(true);
      setError(error);
      //   if (error) return <APIError error={error} />;
    }
  };

  return (
    <div className={styles.eLetterButton}>
      {error && <CenterModal
        open={isModalOpen}
        size="medium"
        onClose={() => {
          setIsModalOpen(false);
          document.body.style.overflow = "visible";
        }}
      >
        <APIError error={error} />
        </CenterModal>}
      <Button
        label={linkDictionaryLables?.button?.link?.title}
        size="large"
        variant="primary"
        className={styles.generateBtn}
        onClick={handleDownload}
      />
      
    </div>
  );
};

export default GenerateLetter;
