import { useState, useEffect } from "react";

function getScreenDetails(specWitdh) {
  const { innerWidth: width, innerHeight: height } = window;
  if(specWitdh)
  {
    return  [width < specWitdh]
  }
  return [width < 1023, width < specWitdh, width, height];
}
export default function useScreenDetails(specWitdh) {
  const [screenDetails, setScreenDetails] = useState(getScreenDetails());
  useEffect(() => {
    function handleResize() {
      setScreenDetails(getScreenDetails(specWitdh));
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenDetails;
}
